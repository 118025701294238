import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrDataTable,  DrPageFilter, DrTagifyInput } from '../../components/component-index';
import { setActiveModal, withActionPromise } from '../../state-management/actions/app.actions';
import { clearCreateStoreInformationState } from '../../state-management/actions/create-store.actions';
import { useNavigate } from 'react-router-dom';
import { getRouteByKey } from '../../routes';
import { Formik } from 'formik';
import * as Yup from 'yup';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { modals, pageFilterTypes } from '../../utils/constants';
import { setInventoryPageSelections } from '../../state-management/actions/settings.actions';
import { CONTROLLERS, apiPost } from '../../utils/axiosApiCalls';
import { getBlockedProductsAsins, saveProductAsinUnBlock } from '../../state-management/actions/inventory.actions';
import { generatePdfReport } from '../../utils/utils';
import { ExportToExcel } from '../../excelexport';

function BlockedProductsPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('blockedProductsPage');
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const myStore = useSelector(state => state.stores.myStore);
    const pageSelections = useSelector(state => state.settings?.pageSelections?.inventoryPageSelections);
    const blockedProductsAsinList = useSelector(state => state.inventory?.blockedProductsAsinList);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        dispatch(withActionPromise(getBlockedProductsAsins(selectedStore?.customerMainSellerId)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore]);

    useEffect(() => {
        if (blockedProductsAsinList?.length > 0) {
            var data = blockedProductsAsinList.find(x => x.customerMainSellerId === selectedStore?.customerMainSellerId)?.asinList;

            var asinlistdata = data?.map(x => ({ asin: x }));

            setTableData(asinlistdata);
        }
        else {
            setTableData([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockedProductsAsinList, blockedProductsAsinList?.length, blockedProductsAsinList?.find(x => x.customerMainSellerId === selectedStore?.customerMainSellerId)?.asinList]);

    const columns = [
        { data: "asin", title: "", minWidth: "40px", maxWidth: "40px", searchable: false, checkall: true, checkData: "asin" },
        { data: "asin", title: "Asin", width: "15%", minWidth: "80px", maxWidth: "80px", searchable: true },
        { data: null, title: "", width: "10%", minWidth: "100px", searchable: false }
    ];

    const columnDefs = [
        {
            targets: 0,
            orderable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                if (rowData.isBlocked) {
                    td.closest("tr").classList.toggle("greyed-out");
                }

                ReactDOM.createRoot(td).render(
                    <>
                        <input type="checkbox" className="ckbox ms-3" name="name" value={rowData.asin}
                            onChange={(e) => {
                                e.target.closest("tr").classList.toggle("selected"); // highlight selected checkbox's row
                            }}
                        />
                    </>
                )
            }
        },
        {
            targets: 1,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.asin}</span><br />
                    </>);
            }
        },
        {
            targets: 2,
            orderable: false,
            className: "text-end",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <button className={`ms-2 me-2 btn btn-warning btn-wave waves-effect waves-light shadow btn-sm`} rel="noreferrer" value=""
                        onClick={(e) => {
                            e.preventDefault();
                            dispatch(withActionPromise(saveProductAsinUnBlock({
                                customerMainSellerId: selectedStore.customerMainSellerId,
                                asinList: [rowData.asin]
                            })));

                        }}>
                        UnBlock

                    </button>
                );
            }
        }
    ];

    const initialValues = {
        asinList: []
    };

    const handleSubmit = async (values) => {

        var blockProducts = {
            customerMainSellerId: selectedStore?.customerMainSellerId,
            //asins: values.asinList.split(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~ ]/).filter((str) => str !== ''),
            asinList: values.asinList,
        }

        // dispatch(createNewProducts(newProduts));

        await apiPost(`/CreateAsinBlocked`, blockProducts, false, CONTROLLERS.PRODUCT, true, true)().then((response) => {
            if (response && response.isSuccess && response.resultType === 1) {
                dispatch(withActionPromise(setInventoryPageSelections({ inventoryStatus: "-1", tableSkip: pageSelections?.tableSkip, tableLength: pageSelections?.tableLength, detailedSearchShownAccordions: pageSelections?.detailedSearchShownAccordions, pagelength: pageSelections?.pagelength, sortingInfo: pageSelections?.sortingInfo })));
                dispatch(withActionPromise(getBlockedProductsAsins(selectedStore?.customerMainSellerId)));
                dispatch(setActiveModal({
                    name: modals.description,
                    isGlobal: true,
                    title: i18next.t('drSweetAlertButton:infoTitle'),
                    header: i18next.t('drSweetAlertButton:infoTitle'),
                    text: i18next.t('drSweetAlertButton:infoTexts.info29'),
                    navigationPath: "/inventory",
                    navigationText: t('gotoInventory')
                }));
            }
        }).catch((err) => {

        });
    }

    const blockProductsSchema = Yup.object().shape({
        // sku: Yup.string()
        //     .min(3, 'Too Short!')
        //     .max(50, 'Too Long!')
        //     .required('SKU is required'),
        // asins: Yup.string()
        //     .min(8, 'Too Short!')
        //     .max(800, 'Too Long!')
        //     .required('Last name is required'),
        asinList: Yup.array()
            // .of(Yup.string()
            //     .min(10, "Asins needs to be ten characters")
            //     .max(10, "Asins needs to be ten characters")
            //     .matches(/^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/, "Asins characters may only contains numbers and letters")
            //     .required("At least one asin is required")
            // )
            .test({
                message: 'At least one asin is required',
                test: arr => arr.length !== 0,
            })
    });

    const selectedRowsOperationsCallBack = (checkedValues, selectedOperation, filterType) => {

        if (selectedOperation === "0" || (selectedOperation === "1" && tableData && tableData?.length > 0)) //export
        {
            let data = tableData?.filter(item1 =>
                !!checkedValues.find(item2 => item1.asin === item2));

            if (selectedOperation === "0" && data?.length > 0) {
                ExportToExcel({ excelData: data, fileName: "blocked_product_asins" });
            }
            else if (selectedOperation === "1" && data?.length > 0) {
                const tableColumn = ["ASIN"];
                // define an empty array of rows
                const tableRows = [];

                // for each ticket pass all its data into an array
                data.forEach(asindata => {
                    const blockedAsinsData = [
                        asindata.asin
                    ];
                    // push each tickcet's info into a row
                    tableRows.push(blockedAsinsData);
                });
                generatePdfReport({ columns: tableColumn, dataSource: tableRows, title: "Blocked Product Asins", orientation: 'l', reportName: "blocked_product_asins" });

            }
        }
        else if (selectedOperation === "7" && tableData?.length > 0) {

            let data = tableData?.filter(item1 =>
                !!checkedValues.find(item2 => item1.asin === item2));

            var asins = data.map(function (o) {
                return o.asin;
            });

            var requestData = {
                customerMainSellerId: selectedStore?.customerMainSellerId,
                asinList: asins
            }

            dispatch(withActionPromise(saveProductAsinUnBlock(requestData)));
        }

    }

    const refreshClickedCallBack = () => {
        if (selectedStore?.customerMainSellerId) {
            dispatch(withActionPromise(getBlockedProductsAsins(selectedStore?.customerMainSellerId)));
        }
    };

    return (
        <>

            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.inventory'), navigationPath: "/inventory" },
                { navigationText: i18next.t('constants:breadCrumps.blockedProducts'), navigationPath: "" }]} >
            </DrBreadCrump>

            <div className="row row-md row-lg d-flex justify-content-center">
                <div className="col-md-8 col-lg-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            <Formik
                                initialValues={initialValues}
                                validateOnBlur={true}
                                onSubmit={handleSubmit}
                                validationSchema={blockProductsSchema}
                                enableReinitialize={true}
                                validateOnMount={true}
                            >
                                {({
                                    isValid,
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    setTouched,
                                    setFieldValue
                                }) => (
                                    <>
                                        <form>
                                            {selectedStore && myStore.sourceMarketList?.length > 0 &&
                                                <section>
                                                    <div className="row row-md row-lg justify-content-center">
                                                        <div className="col-12 px-0">
                                                            <div className="form-group">
                                                                <h6>{t('asinListsTitle')}</h6>
                                                                <DrTagifyInput value={values.asinList}
                                                                    settings={{
                                                                        pattern: /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]{10}$/,
                                                                        delimiters: ",| |:|[\\n\\r]"
                                                                    }}
                                                                    isvalid={(touched.asinList && !errors.asinList)}
                                                                    isinvalid={(touched.asinList && errors.asinList)}
                                                                    className="tags-input-control"
                                                                    onBlur={(e) => {
                                                                        setTouched({ ...touched, 'asinList': true });
                                                                        setFieldValue("asinList", e.detail.tagify.value.map(a => a.value.trim().toUpperCase()));
                                                                    }}
                                                                    onRemove={(e) => {
                                                                        setTouched({ ...touched, 'asinList': true });
                                                                        setFieldValue("asinList", e.detail.tagify.value.map(a => a.value.trim().toUpperCase()));
                                                                    }}
                                                                    onEditUpdated={(e) => {
                                                                        setTouched({ ...touched, 'asinList': true });
                                                                        setFieldValue("asinList", e.detail.tagify.value.map(a => a.value.trim().toUpperCase()));
                                                                    }}>
                                                                </DrTagifyInput>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row row-md row-lg justify-content-end mb-3 mt-2">
                                                        <div className="col-auto px-0">
                                                            <button className={`btn btn-primary btn-wave waves-effect waves-light shadow ${isValid && values.asinList?.length > 0 ? "" : "disabled"}`}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (isValid && values.asinList?.length > 0) {
                                                                        handleSubmit(values);
                                                                        setFieldValue("asinList", []);
                                                                    }
                                                                }}
                                                            >{t('blockProducts')}</button>
                                                        </div>
                                                    </div>

                                                </section>
                                            }
                                        </form>
                                    </>
                                )
                                }
                            </Formik>
                            {selectedStore && myStore.sourceMarketList?.length === 0 && <div className="row row-md row-lg justify-content-center my-3 px-1">

                                <div className="col-auto">
                                    <button className="btn btn-info btn-wave waves-effect waves-light shadow"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate(getRouteByKey("MY_STORE"));
                                        }}>
                                        <i className="me-2 fe fe-chevrons-right"></i>
                                        {t('goToSourceMarketSelection')}
                                    </button>
                                </div>
                            </div>}
                            {!selectedStore && <div className="row row-md row-lg justify-content-center">
                                <div className="col-lg-auto col-md-2">
                                    <button className="btn btn-info btn-wave waves-effect waves-light shadow"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            Promise.all([
                                                dispatch(withActionPromise(clearCreateStoreInformationState()))
                                            ]).then((e) => {
                                                navigate(getRouteByKey("MARKET_SELECTION"));
                                            });;

                                        }}>
                                        <i className="me-2 fe fe-chevrons-right"></i>
                                        {t('gotoMarketSelection')}
                                    </button>
                                </div>
                            </div>
                            }

                            {selectedStore && <>
                                <DrDataTable
                                    data={tableData}
                                    columns={columns}
                                    columnDefs={columnDefs}
                                    doesHaveBorder={false}
                                    headerSearch={true}
                                    // headerSelectbox={true}
                                    minWidth="850px"
                                    search={true}
                                    order={[[1, 'asc']]}
                                    isMultiSelectEnabled={true}
                                    detailedSearchChildren={
                                        <DrPageFilter
                                            refreshClickedCallBack={refreshClickedCallBack}
                                            filterType={pageFilterTypes.blockedAsins}
                                            selectedRowsOperationsCallBack={selectedRowsOperationsCallBack}
                                        >
                                        </DrPageFilter>
                                    }
                                />
                            </>
                            }
                        </div>
                    </div>

                </div>
            </div>


        </>

    )
}

export default BlockedProductsPage;