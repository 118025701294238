import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrButtonDropdown, DrColumnChart, DrDataTable, DrDualDatePicker } from '../../components/component-index';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import { timeOptions } from '../../components/hoc/Constants/dr-constants.component';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { buttonVariants } from '../../utils/constants';
import { getWarehouseHomePageData } from '../../state-management/actions/warehouses.actions';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { setWarehouseHomePageSelections } from '../../state-management/actions/settings.actions';

function WareHouseHomePage() {
  const dispatch = useDispatch();
  const { t } = useTranslation('wareHouseHomePage');
  const [warehouseMarketOptions, setWarehouseMarketOptions] = useState(null);
  const pageSelections = useSelector(state => state.settings?.pageSelections?.warehouseHomePageSelections);
  const customerInfo = useSelector(state => state.customer.customerInfo);
  const warehouseHomePageData = useSelector(state => state.warehouses.warehouseHomePageData);
  const selectedStoreCurrencyType = useSelector(state => state.settings?.selectedStoreCurrencyType);

  useEffect(() => {
    if (pageSelections?.marketOption) {
      dispatch(getWarehouseHomePageData(
        {
          marketId: pageSelections?.marketOption
        }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    if (pageSelections?.marketOption) {
      dispatch(getWarehouseHomePageData(
        {
          marketId: pageSelections?.marketOption
        }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSelections?.marketOption]);

  return (
    <>
      <DrBreadCrump > </DrBreadCrump>
      {/* <div className="row justify-content-end mb-2">
        <div className="col-12">
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Tracking Id, Order Id"  aria-describedby="button-addon2"></input>
            <button className="btn btn-teal" type="button" id="button-addon2">Search</button>
          </div>
        </div>
      </div> */}

      <div className="row" >
        <div className="col-xl-8 d-flex">
          <div id="chart" className="card custom-card chart">
            <div className="card-body">
              <div className="row justify-content-start mb-2">
                <div className="col">
                  <DrColumnChart
                    chartData={{
                      // series: warehouseHomePageData?.customerOrderGraphics?.axis?.sort((b, a) => moment(a.xData, "YYYY-MM-DDThh:mm:ss").valueOf() - moment(b.xData, "YYYY-MM-DDThh:mm:ss").valueOf()).map(x => Number(x.yData).toFixed(1)) || [],
                      // categories: warehouseHomePageData?.customerOrderGraphics?.axis?.sort((b, a) => moment(a.xData, "YYYY-MM-DDThh:mm:ss").valueOf() - moment(b.xData, "YYYY-MM-DDThh:mm:ss").valueOf()).map((x, index) => {
                      //   return (
                      //     index % (warehouseHomePageData?.customerOrderGraphics?.axis.length <= 31 ? 2 : (warehouseHomePageData?.customerOrderGraphics?.axis.length > 31 && warehouseHomePageData?.customerOrderGraphics?.axis.length <= 90 ? 5 : 13)) === 0 ? moment(x.xData).format('L') : ""
                      //   )
                      // }) || [],
                      data: warehouseHomePageData?.customerOrderGraphics?.axis?.sort((b, a) => moment(b.xData, "YYYY-MM-DDThh:mm:ss").diff(moment.utc(a.xData, "YYYY-MM-DDThh:mm:ss"))),
                      colors: ['var(--info-color)', 'var(--danger-color)', 'var(--success-color)'],
                      peakText: "Order Counts",
                      // title: "Inventory Status"
                    }}
                  ></DrColumnChart>
                </div>
              </div>
            </div>
          </div>
          {/* <DrLineChart chartData={chartData} options={chartOptions} /> */}

        </div>
        <div className="col-xl-4 d-flex">
          <div id="sale-summary" className="card custom-card">
            <div className="card-header">
              <div className="card-title">
                Sales Summary
              </div>
            </div>

            <div className="card-body">
              <div className="table-responsive ">
                <table className="table table-hover table-bordered border-primary">
                  <thead>
                    <tr>
                      <th>Period</th>
                      <th className="text-center">Sales</th>
                      <th className="text-center">Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Today</th>
                      <td className="text-center">{selectedStoreCurrencyType.currencyCode} {Number(warehouseHomePageData?.salesSumary?.today?.totalSalePrice || 0.00).toFixed(2)}</td>
                      <td className="text-center">{warehouseHomePageData?.salesSumary?.today?.totalSaleCount}</td>
                    </tr>
                    <tr>
                      <th scope="row">7 Days</th>
                      <td className="text-center">{selectedStoreCurrencyType.currencyCode} {Number(warehouseHomePageData?.salesSumary?.sevenDay?.totalSalePrice || 0.00).toFixed(2)}</td>
                      <td className="text-center">{warehouseHomePageData?.salesSumary?.sevenDay?.totalSaleCount}</td>
                    </tr>
                    <tr>
                      <th scope="row">15 Days</th>
                      <td className="text-center">{selectedStoreCurrencyType.currencyCode} {Number(warehouseHomePageData?.salesSumary?.fifteenDay?.totalSalePrice || 0.00).toFixed(2)}</td>
                      <td className="text-center">{warehouseHomePageData?.salesSumary?.fifteenDay?.totalSaleCount}</td>
                    </tr>
                    <tr>
                      <th scope="row">30 Days</th>
                      <td className="text-center">{selectedStoreCurrencyType.currencyCode} {Number(warehouseHomePageData?.salesSumary?.thirtyDay?.totalSalePrice || 0.00).toFixed(2)}</td>
                      <td className="text-center">{warehouseHomePageData?.salesSumary?.thirtyDay?.totalSaleCount}</td>
                    </tr>
                    <tr>
                      <th scope="row">90 Days</th>
                      <td className="text-center">{selectedStoreCurrencyType.currencyCode} {Number(warehouseHomePageData?.salesSumary?.ninetyDay?.totalSalePrice || 0.00).toFixed(2)}</td>
                      <td className="text-center">{warehouseHomePageData?.salesSumary?.ninetyDay?.totalSaleCount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>

  )
}
export default WareHouseHomePage