import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import accountPageEN from '../src/locales/en/Pages/account-page.json';
import accountPageTR from '../src/locales/tr/Pages/account-page.json';
import subscriptionPlanPageTR from '../src/locales/tr/Pages/subscription-plan-page.json';
import subscriptionPlanPageEN from '../src/locales/en/Pages/subscription-plan-page.json';
import registerPageTR from '../src/locales/tr/Pages/register-page.json';
import registerPageEN from '../src/locales/en/Pages/register-page.json';
import invoicePageTR from '../src/locales/tr/Pages/invoice-page.json';
import invoicePageEN from '../src/locales/en/Pages/invoice-page.json';
import paymentPageTR from '../src/locales/tr/Pages/payment-page.json';
import paymentPageEN from '../src/locales/en/Pages/payment-page.json';
import bestsellingPageTR from '../src/locales/tr/Pages/bestselling-page.json';
import bestsellingPageEN from '../src/locales/en/Pages/bestselling-page.json';
import duplicatePageTR from '../src/locales/tr/Pages/duplicate-page.json';
import duplicatePageEN from '../src/locales/en/Pages/duplicate-page.json';
import productvariantsPageTR from '../src/locales/tr/Pages/productvariants-page.json';
import productvariantsPageEN from '../src/locales/en/Pages/productvariants-page.json';
import updatelistPageTR from '../src/locales/tr/Pages/updatelist-page.json';
import updatelistPageEN from '../src/locales/en/Pages/updatelist-page.json';
import introductionPageEN from '../src/locales/en/Pages/introduction-page.json';
import introductionPageTR from '../src/locales/tr/Pages/introduction-page.json';
import warehousePageTR from '../src/locales/tr/Pages/warehouse-page.json';
import warehousePageEN from '../src/locales/en/Pages/warehouse-page.json';
import generalPageTR from '../src/locales/tr/Pages/general-page.json';
import generalPageEN from '../src/locales/en/Pages/general-page.json';
import profilePageTR from '../src/locales/tr/Pages/profile-page.json';
import profilePageEN from '../src/locales/en/Pages/profile-page.json';
import creditPageTR from '../src/locales/tr/Pages/credit-page.json';
import creditPageEN from '../src/locales/en/Pages/credit-page.json';
import invoicesPageTR from '../src/locales/tr/Pages/invoices-page.json';
import invoicesPageEN from '../src/locales/en/Pages/invoices-page.json';
import categoriesPageTR from '../src/locales/tr/Pages/categories-page.json';
import categoriesPageEN from '../src/locales/en/Pages/categories-page.json';
import blacklistPageTR from '../src/locales/tr/Pages/blacklist-page.json';
import blacklistPageEN from '../src/locales/en/Pages/blacklist-page.json';
import brandlistPageTR from '../src/locales/tr/Pages/brandlist-page.json';
import brandlistPageEN from '../src/locales/en/Pages/brandlist-page.json';
import brandblackwhitelistPageEN from '../src/locales/en/Pages/brandblackwhitelist-page.json';
import brandblackwhitelistPageTR from '../src/locales/tr/Pages/brandblackwhitelist-page.json';
import commonBrandPoolPageEN from '../src/locales/en/Pages/common-brand-pool-page.json';
import commonBrandPoolPageTR from '../src/locales/tr/Pages/common-brand-pool-page.json';
import commonProductPoolPageEN from '../src/locales/en/Pages/common-product-pool-page.json';
import commonProductPoolPageTR from '../src/locales/tr/Pages/common-product-pool-page.json';
import privacyAndPolicyPageTR from '../src/locales/tr/Pages/privacyAndPolicy-page.json';
import privacyAndPolicyPageEN from '../src/locales/en/Pages/privacyAndPolicy-page.json';
import salesReportPageTR from '../src/locales/tr/Pages/sales-report-page.json';
import salesReportPageEN from '../src/locales/en/Pages/sales-report-page.json';
import subSellersPageTR from '../src/locales/tr/Pages/subsellers-page.json';
import subSellersPageEN from '../src/locales/en/Pages/subsellers-page.json';
import ordersPageTR from '../src/locales/tr/Pages/orders-page.json';
import ordersPageEN from '../src/locales/en/Pages/orders-page.json';
import profitReportPageTR from '../src/locales/tr/Pages/profitReport-page.json';
import profitReportPageEN from '../src/locales/en/Pages/profitReport-page.json';
import problematicProductReportPageTR from '../src/locales/tr/Pages/problematicProductReport-page.json';
import problematicProductReportPageEN from '../src/locales/en/Pages/problematicProductReport-page.json';
import errorPageEN from '../src/locales/en/Pages/error-page.json';
import errorPageTR from '../src/locales/tr/Pages/error-page.json';
import inventoryPageEN from '../src/locales/en/Pages/inventory-page.json';
import inventoryPageTR from '../src/locales/tr/Pages/inventory-page.json';
import marketSelectionPageTR from '../src/locales/tr/Pages/market-selection-page.json';
import marketSelectionPageEN from '../src/locales/en/Pages/market-selection-page.json';
import storePreferencesPageTR from '../src/locales/tr/Pages/store-preferences-page.json';
import storePreferencesPageEN from '../src/locales/en/Pages/store-preferences-page.json';
import storeFiltersPageTR from '../src/locales/tr/Pages/store-filters-page.json';
import storeFiltersPageEN from '../src/locales/en/Pages/store-filters-page.json';
import restrictedandexcludeproductsPageEN from '../src/locales/en/Pages/restrictedandexcludeproducts-page.json';
import restrictedandexcludeproductsPageTR from '../src/locales/tr/Pages/restrictedandexcludeproducts-page.json';
import storePricingPageEN from '../src/locales/en/Pages/store-pricing-page.json';
import storePricingPageTR from '../src/locales/tr/Pages/store-pricing-page.json';
import addNewProductPageEN from '../src/locales/en/Pages/addnewproduct-page.json';
import addNewProductPageTR from '../src/locales/tr/Pages/addnewproduct-page.json';
import searchTaskPageEN from '../src/locales/en/Pages/search-tasks-page.json';
import searchTaskPageTR from '../src/locales/tr/Pages/search-tasks-page.json';
import shippingPageEN from '../src/locales/en/Pages/shipping-page.json';
import shippingPageTR from '../src/locales/tr/Pages/shipping-page.json';
import productDetailPageEN from '../src/locales/en/Pages/productdetail-page.json';
import productDetailPageTR from '../src/locales/tr/Pages/productdetail-page.json';
import blockedWordsPageEN from './locales/en/Pages/blocked-words-page.json';
import blockedWordsPageTR from './locales/tr/Pages/blocked-words-page.json';
import smartPricerPageEN from '../src/locales/en/Pages/smartpricer-page.json';
import smartPricerPageTR from '../src/locales/tr/Pages/smartpricer-page.json';
import congratulationPageEN from '../src/locales/en/Pages/congratulation-page.json';
import congratulationPageTR from '../src/locales/tr/Pages/congratulation-page.json';
import connectAmazonPageEN from '../src/locales/en/Pages/connect-amazon-page.json';
import connectAmazonPageTR from '../src/locales/tr/Pages/connect-amazon-page.json';
import completeCreateStorePageEN from '../src/locales/en/Pages/complete-createstore-page.json';
import completeCreateStorePageTR from '../src/locales/tr/Pages/complete-createstore-page.json';
import walletPageEN from '../src/locales/en/Pages/wallet-page.json';
import walletPageTR from '../src/locales/tr/Pages/wallet-page.json';
import homePageEN from '../src/locales/en/Pages/home-page.json';
import homePageTR from '../src/locales/tr/Pages/home-page.json';
import blockedProductsPageEN from '../src/locales/en/Pages/blockedproducts-page.json';
import blockedProductsPageTR from '../src/locales/tr/Pages/blockedproducts-page.json';
import nonPrizedProductsPageEN from '../src/locales/en/Pages/nonprizedproducts-page.json';
import nonPrizedProductsPageTR from '../src/locales/tr/Pages/nonprizedproducts-page.json';
import warehousePreferencesPageEN from '../src/locales/en/Pages/warehouse-preferences-page.json';
import warehousePreferencesPageTR from '../src/locales/tr/Pages/warehouse-preferences-page.json';
import warehouseLostPackagesPageEN from '../src/locales/en/Pages/warehouse-lost-packages-page.json';
import warehouseLostPackagesPageTR from '../src/locales/tr/Pages/warehouse-lost-packages-page.json';
import personalLostPackagesPageEN from '../src/locales/en/Pages/personal-lost-packages-page.json';
import personalLostPackagesPageTR from '../src/locales/tr/Pages/personal-lost-packages-page.json';
import copyrightBrandsPageEN from '../src/locales/en/Pages/copyrightbrands-page.json';
import copyrightBrandsPageTR from '../src/locales/tr/Pages/copyrightbrands-page.json';
import createPackagesPageEN from '../src/locales/en/Pages/create-packages-page.json';
import createPackagesPageTR from '../src/locales/tr/Pages/create-packages-page.json';
import createCouponsPageEN from '../src/locales/en/Pages/create-coupons-page.json';
import createCouponsPageTR from '../src/locales/tr/Pages/create-coupons-page.json';
import emailForwardingPageEN from '../src/locales/en/Pages/email-forwarding-page.json';
import emailForwardingPageTR from '../src/locales/tr/Pages/email-forwarding-page.json';
import feedbackConfigPageEN from '../src/locales/en/Pages/feedback-config-page.json';
import feedbackConfigPageTR from '../src/locales/tr/Pages/feedback-config-page.json';

import drNavigatorEN from '../src/locales/en/Components/dr-navigator.json';
import drNavigatorTR from '../src/locales/tr/Components/dr-navigator.json';
import drSteptrackerEN from '../src/locales/en/Components/dr-steptracker.json';
import drSteptrackerTR from '../src/locales/tr/Components/dr-steptracker.json';
import drLoginEN from '../src/locales/en/Components/dr-login.json';
import drLoginTR from '../src/locales/tr/Components/dr-login.json';
import drSidebarTR from '../src/locales/tr/Components/dr-sidebar.json';
import drSidebarEN from '../src/locales/en/Components/dr-sidebar.json';
import drHeaderTR from '../src/locales/tr/Components/dr-header.json';
import drHeaderEN from '../src/locales/en/Components/dr-header.json';
import drForgotPasswordTR from '../src/locales/tr/Components/dr-forgotpassword.json';
import drForgotPasswordEN from '../src/locales/en/Components/dr-forgotpassword.json';
import drDualDatePickerTR from '../src/locales/tr/Components/dr-dualdatepicker.json';
import drDualDatePickerEN from '../src/locales/en/Components/dr-dualdatepicker.json';
import drWarehousePricingTR from '../src/locales/tr/Components/dr-warehousepricing.json';
import drWarehousePricingEN from '../src/locales/en/Components/dr-warehousepricing.json';
import drPageFilterTR from '../src/locales/tr/Components/dr-pagefilter.json';
import drPageFilterEN from '../src/locales/en/Components/dr-pagefilter.json';
import drCaptchaModalTR from '../src/locales/tr/Components/dr-captcha-modal.json';
import drCaptchaModalEN from '../src/locales/en/Components/dr-captcha-modal.json';
import drSearchTaskProductsTR from '../src/locales/tr/Components/dr-search-task-products-modal.json';
import drSearchTaskProductsEN from '../src/locales/en/Components/dr-search-task-products-modal.json';
import drMailTemplatesTR from '../src/locales/tr/Components/dr-mail-templates.json';
import drMailTemplatesEN from '../src/locales/en/Components/dr-mail-templates.json';
import drAddMerhantTR from '../src/locales/tr/Components/dr-add-merchant-list-modal.json';
import drAddMerhantEN from '../src/locales/en/Components/dr-add-merchant-list-modal.json';
import drSavePageFilterModalTR from '../src/locales/tr/Components/dr-pagefilter-save-modal.json';
import drSavePageFilterModalEN from '../src/locales/en/Components/dr-pagefilter-save-modal.json';
import drSweetAlertButtonTR from '../src/locales/tr/Components/dr-sweetalert-button.json';
import drSweetAlertButtonEN from '../src/locales/en/Components/dr-sweetalert-button.json';
import inventoryPageFilterTR from '../src/locales/tr/Components/dr-inventory-page-filter.json';
import inventoryPageFilterEN from '../src/locales/en/Components/dr-inventory-page-filter.json';

import drPackageWarningsTR from '../src/locales/tr/Components/dr-package-warnings.json';
import drPackageWarningsEN from '../src/locales/en/Components/dr-package-warnings.json';

import constantsEN from '../src/locales/en/Constants/constants.json';
import constantsTR from '../src/locales/tr/Constants/constants.json';

import drDatatableTR from '../src/locales/tr/Components/dr-datatable.json';

const resources = {
  en: {
    accountPage: accountPageEN,
    subscriptionPlanPage: subscriptionPlanPageEN,
    drNavigator: drNavigatorEN,
    drSteptracker: drSteptrackerEN,
    registerPage: registerPageEN,
    invoicePage: invoicePageEN,
    paymentPage: paymentPageEN,
    bestsellingPage: bestsellingPageEN,
    duplicatePage: duplicatePageEN,
    productvariantsPage: productvariantsPageEN,
    updatelistPage: updatelistPageEN,
    restrictedandexcludeproductsPage: restrictedandexcludeproductsPageEN,
    categoriesPage: categoriesPageEN,
    blacklistPage: blacklistPageEN,
    brandblackwhitelistPage: brandblackwhitelistPageEN,
    brandlistPage: brandlistPageEN,
    drLogin: drLoginEN,
    introductionpage: introductionPageEN,
    drSidebar: drSidebarEN,
    generalpage: generalPageEN,
    profilepage: profilePageEN,
    creditpage: creditPageEN,
    invoicesPage: invoicesPageEN,
    errorPage: errorPageEN,
    drHeader: drHeaderEN,
    warehousepage: warehousePageEN,
    inventoryPage: inventoryPageEN,
    drForgotPassword: drForgotPasswordEN,
    drDualDatePicker: drDualDatePickerEN,
    marketSelectionPage: marketSelectionPageEN,
    storePreferencesPage: storePreferencesPageEN,
    storeFiltersPage: storeFiltersPageEN,
    constants: constantsEN,
    privacyAndPolicyPage: privacyAndPolicyPageEN,
    salesReportPage: salesReportPageEN,
    storePricingPage: storePricingPageEN,
    drWarehousePricing: drWarehousePricingEN,
    subSellersPage: subSellersPageEN,
    drPageFilter: drPageFilterEN,
    ordersPage: ordersPageEN,
    profitReportPage: profitReportPageEN,
    problematicProductReportPage: problematicProductReportPageEN,
    addNewProductPage: addNewProductPageEN,
    searchTaskPage: searchTaskPageEN,
    drCaptchaModal: drCaptchaModalEN,
    drSearchTaskProducts: drSearchTaskProductsEN,
    shippingPage: shippingPageEN,
    productDetailPage: productDetailPageEN,
    blockedWordsPage: blockedWordsPageEN,
    smartPricerPage: smartPricerPageEN,
    drMailTemplates: drMailTemplatesEN,
    congratulationPage: congratulationPageEN,
    connectAmazonPage: connectAmazonPageEN,
    completeCreateStorePage: completeCreateStorePageEN,
    walletPage: walletPageEN,
    drAddMerhant: drAddMerhantEN,
    homePage: homePageEN,
    blockedProductsPage: blockedProductsPageEN,
    warehousePreferencesPage: warehousePreferencesPageEN,
    drSavePageFilterModal: drSavePageFilterModalEN,
    drSweetAlertButton: drSweetAlertButtonEN,
    warehouseLostPackagesPage: warehouseLostPackagesPageEN,
    personalLostPackagesPage: personalLostPackagesPageEN,
    nonPrizedProductsPage: nonPrizedProductsPageEN,
    copyrightBrandsPage: copyrightBrandsPageEN,
    createPackagesPage: createPackagesPageEN,
    createCouponsPage: createCouponsPageEN,
    commonBrandPoolPage: commonBrandPoolPageEN,
    commonProductPoolPage: commonProductPoolPageEN,
    emailForwardingPage: emailForwardingPageEN,
    feedbackConfigPage: feedbackConfigPageEN,
    inventoryPageFilter: inventoryPageFilterEN,
    drPackageWarnings: drPackageWarningsEN
  },
  tr: {
    accountPage: accountPageTR,
    subscriptionPlanPage: subscriptionPlanPageTR,
    drNavigator: drNavigatorTR,
    drSteptracker: drSteptrackerTR,
    registerPage: registerPageTR,
    invoicePage: invoicePageTR,
    paymentPage: paymentPageTR,
    bestsellingPage: bestsellingPageTR,
    duplicatePage: duplicatePageTR,
    productvariantsPage: productvariantsPageTR,
    updatelistPage: updatelistPageTR,
    restrictedandexcludeproductsPage: restrictedandexcludeproductsPageTR,
    categoriesPage: categoriesPageTR,
    blacklistPage: blacklistPageTR,
    brandblackwhitelistPage: brandblackwhitelistPageTR,
    brandlistPage: brandlistPageTR,
    drLogin: drLoginTR,
    introductionpage: introductionPageTR,
    drSidebar: drSidebarTR,
    drDatatable: drDatatableTR,
    generalpage: generalPageTR,
    profilepage: profilePageTR,
    creditpage: creditPageTR,
    invoicesPage: invoicesPageTR,
    errorPage: errorPageTR,
    drHeader: drHeaderTR,
    warehousepage: warehousePageTR,
    inventoryPage: inventoryPageTR,
    drForgotPassword: drForgotPasswordTR,
    drDualDatePicker: drDualDatePickerTR,
    marketSelectionPage: marketSelectionPageTR,
    storePreferencesPage: storePreferencesPageTR,
    storeFiltersPage: storeFiltersPageTR,
    constants: constantsTR,
    privacyAndPolicyPage: privacyAndPolicyPageTR,
    salesReportPage: salesReportPageTR,
    storePricingPage: storePricingPageTR,
    drWarehousePricing: drWarehousePricingTR,
    subSellersPage: subSellersPageTR,
    drPageFilter: drPageFilterTR,
    ordersPage: ordersPageTR,
    profitReportPage: profitReportPageTR,
    problematicProductReportPage: problematicProductReportPageTR,
    addNewProductPage: addNewProductPageTR,
    searchTaskPage: searchTaskPageTR,
    drCaptchaModal: drCaptchaModalTR,
    drSearchTaskProducts: drSearchTaskProductsTR,
    shippingPage: shippingPageTR,
    productDetailPage: productDetailPageTR,
    blockedWordsPage: blockedWordsPageTR,
    smartPricerPage: smartPricerPageTR,
    drMailTemplates: drMailTemplatesTR,
    congratulationPage: congratulationPageTR,
    connectAmazonPage: connectAmazonPageTR,
    completeCreateStorePage: completeCreateStorePageTR,
    walletPage: walletPageTR,
    drAddMerhant: drAddMerhantTR,
    homePage: homePageTR,
    blockedProductsPage: blockedProductsPageTR,
    warehousePreferencesPage: warehousePreferencesPageTR,
    drSavePageFilterModal: drSavePageFilterModalTR,
    drSweetAlertButton: drSweetAlertButtonTR,
    warehouseLostPackagesPage: warehouseLostPackagesPageTR,
    personalLostPackagesPage: personalLostPackagesPageTR,
    nonPrizedProductsPage: nonPrizedProductsPageTR,
    copyrightBrandsPage: copyrightBrandsPageTR,
    createPackagesPage: createPackagesPageTR,
    createCouponsPage: createCouponsPageTR,
    commonBrandPoolPage: commonBrandPoolPageTR,
    commonProductPoolPage: commonProductPoolPageTR,
    copyrightBrandsPage: copyrightBrandsPageTR,
    emailForwardingPage: emailForwardingPageTR,
    feedbackConfigPage: feedbackConfigPageTR,
    inventoryPageFilter: inventoryPageFilterTR,
    drPackageWarnings: drPackageWarningsTR
  }
};

i18n
  // .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    resources: resources,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // backend: {
    //   // for all available options read the backend's repository readme file
    //   loadPath: '../src/locales/{{lng}}/Components/{{ns}}.json'
    // }
  });

export default i18n;
