import { call, put, takeLatest, all } from 'redux-saga/effects'
import { apiDelete, apiGet, apiPost, apiPut, CONTROLLERS } from "../../utils/axiosApiCalls"
import safe from '../errorHandlingUtils'
import types, { getInvoiceSuccess, getLatestOrdersSuccess, getOrderDetailSaleSummary, getOrderDetailSaleSummarySuccess, getOrderItemSuccess, getOrderNotes, getOrderNotesSuccess, getOrderShippingCalculateSuccess, getOrderTrackingStatusSuccess, getOrdersSuccess, getWarehouseOrdersSuccess } from '../actions/orders.actions';
import i18next from 'i18next';
import Swal from 'sweetalert2';
import { getOrderStatusCount } from '../actions/store.actions';
import { clearActiveBuyoutCarrierSelectionModal } from '../actions/app.actions';

function* runGetOrders(action) {
    const payload = action.payload;
    const response = yield call(apiPost(`/GetOrders`, payload, false, CONTROLLERS.ORDER, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield all([
            put(getOrdersSuccess(response.data)),
            put(getOrderStatusCount({ customerMainSellerId: payload?.customerMainSellerId, startDate: payload?.startOrderDate, endDate: payload?.endOrderDate }))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error15') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetLatestOrders(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/GetOrders`, payload, false, CONTROLLERS.ORDER, true, false));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield all([
            put(getLatestOrdersSuccess(response.data)),
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error15') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetOrdersWarehouse(action) {
    const payload = action.payload;

    // var path = `/GetOrderMarketId?marketId=${payload.marketId}`;
    // path += `&orderStatus=${payload.orderStatus}`;
    // path += `&createdAfter=${payload.createdAfter}`;

    const response = yield call(apiPost(`/GetOrderMarketId`, payload, false, CONTROLLERS.ORDER, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(getWarehouseOrdersSuccess(response)),
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error15') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetOrderItem(action) {
    const payload = action.payload;

    var path = `/GetOrderItem?OrderId=${payload.orderId}`;

    const response = yield call(apiGet(path, CONTROLLERS.ORDER, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getOrderItemSuccess(response.data)),
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), (i18next.t('drSweetAlertButton:errorTexts.error16') + " " + (response && response.message ? response.message : "")), 'error'));
    }
}

function* runGetOrderItemByOrderNumber(action) {
    const payload = action.payload;

    var path = `/GetOrderItemByOrderNumber?OrderNumber=${payload.orderNumber}&CustomerMainSellerId=${payload.customerMainSellerId}`;

    const response = yield call(apiGet(path, CONTROLLERS.ORDER, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getOrderItemSuccess(response.data)),
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), (i18next.t('drSweetAlertButton:errorTexts.error16') + " " + (response && response.message ? response.message : "")), 'error'));
    }
}

function* runGetOrderDetailSaleSummary(action) {
    const payload = action.payload;

    var path = `/GetOrderDetailSaleSummary?OrderId=${payload.orderId}`;

    const response = yield call(apiGet(path, CONTROLLERS.ORDER, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getOrderDetailSaleSummarySuccess(response.data)),
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetOrderTrackingStatus(action) {
    const payload = action.payload;

    var path = `/GetSellerOrderStatusByOrderId?OrderId=${payload.orderId}`;

    const response = yield call(apiGet(path, CONTROLLERS.ORDER, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getOrderTrackingStatusSuccess(response.data)),
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runUpdateOrderNumber(action) {
    const payload = action.payload;

    const response = yield call(apiPut(`/UpdateOrderNumber`, payload, false, CONTROLLERS.ORDER, true));
    if (response && response.isSuccess && response.resultType === 1) {

        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info30'), 'success'))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error62'), 'error'));
    }
}

function* runGetOrderNotes(action) {
    const payload = action.payload;

    var path = `/GetOrderNote?orderId=${payload}`;

    const response = yield call(apiGet(path, CONTROLLERS.ORDER, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield all([
            put(getOrderNotesSuccess(response.data)),
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage'), 'error'));
    }
}

function* runAddOrderNote(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/AddOrderNote`, payload, false, CONTROLLERS.ORDER, true, false));
    if (response && response.isSuccess && response.resultType === 1) {
        yield put(getOrderNotes(payload.orderId))
    }
    else {
        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage'), 'error'))
        ]);
    }
}

function* runDeleteOrderNote(action) {
    const payload = action.payload;
    var path = `/DeleteOrderNote?id=${payload.noteId}`;
    const response = yield call(apiDelete(path, CONTROLLERS.ORDER, true, true));
    if (response && response.isSuccess) {
        yield all([
            put(getOrderNotes(payload.orderId)),

            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))


        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage'), 'error'));
    }
}

function* runUpsertOrderFlag(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/UpsertOrderFlag`, payload, false, CONTROLLERS.ORDER, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'));
    }
    else {
        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage'), 'error'))
        ]);
    }
}

function* runGetInvoice(action) {
    var payload = action.payload;
    var path = `?CustomerMainSellerId=${payload.customerMainSellerId}&OrderId=${payload.orderId}`;

    const response = yield call(apiGet(path, CONTROLLERS.INVOICE, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield put(getInvoiceSuccess(response.data));
    }
    else {
        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:warningTitle'), i18next.t('drSweetAlertButton:warningTexts.warning1'), 'warning'))
        ]);
    }
}

function* runGetOrderShippingCalculate(action) {
    var payload = action.payload;
    var path = `/GetOrderShippingCalculate?CustomerMainSellerId=${payload.customerMainSellerId}&OrderId=${payload.orderId}`;

    const response = yield call(apiGet(path, CONTROLLERS.ORDER, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield put(getOrderShippingCalculateSuccess(response.data));
    }
    else {
        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage'), 'error'))
        ]);
    }
}

function* runUpdateOrderShipping(action) {
    const payload = action.payload;

    const response = yield call(apiPut(`/UpdateOrderShipping`, payload.orderShippingRequest, false, CONTROLLERS.ORDER, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield put(clearActiveBuyoutCarrierSelectionModal());
        window.open(payload.paymentUrl, '_blank');
    }
    else {
        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage'), 'error'))
        ]);
    }
}

function* runUpdateOrderDetailSaleSummary(action) {
    const payload = action.payload;

    const response = yield call(apiPut(`/UpdateOrderDetailSaleSummary`, payload.requestData, false, CONTROLLERS.ORDER, true, true));
    if (response && response.isSuccess && response.resultType === 1) {

        yield all([
            put(getOrderDetailSaleSummary({ orderId: payload.orderId })),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
        ]);
    }
    else {
        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage'), 'error'))
        ]);
    }
}

export default function* ordersSaga() {
    yield all([
        takeLatest(types.GET_ORDERS_REQUESTED, safe(runGetOrders, types.GET_ORDERS_FAILED)),
        takeLatest(types.GET_WAREHOUSE_ORDERS_REQUESTED, safe(runGetOrdersWarehouse, types.GET_WAREHOUSE_ORDERS_FAILED)),
        takeLatest(types.GET_LATEST_ORDERS_REQUESTED, safe(runGetLatestOrders, types.GET_LATEST_ORDERS_FAILED)),
        takeLatest(types.GET_ORDER_ITEM_REQUESTED, safe(runGetOrderItem, types.GET_ORDER_ITEM_FAILED)),
        takeLatest(types.GET_ORDER_ITEM_BY_ORDERNUMBER_REQUESTED, safe(runGetOrderItemByOrderNumber, types.GET_ORDER_ITEM_FAILED)),
        takeLatest(types.GET_ORDER_ITEM_SUMMARY_REQUESTED, safe(runGetOrderDetailSaleSummary, types.GET_ORDER_ITEM_SUMMARY_FAILED)),
        takeLatest(types.GET_ORDER_TRACKING_STATUS_REQUESTED, safe(runGetOrderTrackingStatus, types.GET_ORDER_TRACKING_STATUS_FAILED)),
        takeLatest(types.UPDATE_ORDERITEM_AMAZON_ORDER_NUMBER_REQUESTED, safe(runUpdateOrderNumber)),
        takeLatest(types.GET_ORDER_NOTES_REQUESTED, safe(runGetOrderNotes)),
        takeLatest(types.SAVE_ORDER_NOTE_REQUESTED, safe(runAddOrderNote)),
        takeLatest(types.DELETE_ORDER_NOTE_REQUESTED, safe(runDeleteOrderNote)),
        takeLatest(types.SAVE_ORDER_FLAG_REQUESTED, safe(runUpsertOrderFlag)),
        takeLatest(types.GET_INVOICE_REQUESTED, safe(runGetInvoice)),
        takeLatest(types.GET_ORDER_SHIPPING_CALCULATE_REQUESTED, safe(runGetOrderShippingCalculate)),
        takeLatest(types.UPDATE_ORDER_SHIPPING_REQUESTED, safe(runUpdateOrderShipping)),
        takeLatest(types.UPDATE_ORDER_DETAIL_SUMMARY_REQUESTED, safe(runUpdateOrderDetailSaleSummary)),
    ]);
}