import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrDataTable, DrInput, DrPageFilter, DrSweetAlertButton } from '../../components/component-index';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import { getProductsBrands, saveProductBrandBlock, upsertProductSafeBrand } from '../../state-management/actions/inventory.actions';
import { useDispatch, useSelector } from 'react-redux';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { pageFilterTypes, sweetAlertVariants } from '../../utils/constants';
import { generatePdfReport } from '../../utils/utils';
import { ExportToExcel } from '../../excelexport';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

function BrandListPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('brandlistPage');
  const selectedStore = useSelector(state => state.stores.selectedStore);
  const productsBrands = useSelector(state => state.inventory.productsBrands);
  const selectedStoreCurrencyType = useSelector(state => state.settings?.selectedStoreCurrencyType);

  useEffect(() => {
    if (selectedStore?.customerMainSellerId) {
      dispatch(withActionPromise(getProductsBrands(selectedStore?.customerMainSellerId)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    word: ''
  };

  const handleSubmit = async (values, { resetForm }) => {
    dispatch(saveProductBrandBlock({ customerMainSellerId: selectedStore.customerMainSellerId, productBrandNames: [values.word], isBlocked: true }));

  };

  const columns = [
    { data: "id", title: "", minWidth: "40px", maxWidth: "40px", searchable: false, checkall: true, checkData: "id" },
    { data: "brandName", title: t('dataTable.brand'), width: "15%", searchable: true },
    { data: "estimatedProfit", title: t('dataTable.estimatedProfit'), width: "15%", searchable: true },
    { data: "brandOrderCount", title: t('dataTable.order'), width: "15%", searchable: true },
    { data: "productBrandCount", title: t('dataTable.productCount'), width: "15%", searchable: true },
    { data: "isBlocked", title: "", width: "10%", minWidth: "100px", searchable: false }
  ];

  const columnDefs = [
    {
      targets: 0,
      orderable: false,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        if (rowData.isBlocked) {
          td.closest("tr").classList.toggle("greyed-out");
        }

        ReactDOM.createRoot(td).render(
          <>

            <input type="checkbox" className="ckbox ms-3" name="name" value={rowData.id}
              onChange={(e) => {
                e.target.closest("tr").classList.toggle("selected"); // highlight selected checkbox's row
              }}
            />

          </>
        )
      }
    },
    {
      targets: 1,
      orderable: true,
      className: "text-center",
      width: "20%",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <button className="btn btn-link btn-sm btn-wave waves-effect waves-light"
              onClick={(e) => {
                e.preventDefault();
                navigate('/inventory/b/' + rowData.id);
              }}
            >
              {rowData.brandName}
            </button>
          </>)
      }
    },
    {
      targets: 2,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            {rowData.estimatedProfit && <span className="badge bg-success my-auto mx-0 p-0">{selectedStoreCurrencyType.currencyCode}{Number(rowData.estimatedProfit).toFixed(2)}</span>}
          </>);
      }
    },
    {
      targets: 3,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <div className="text-center" style={{ fontSize: '16px' }}>
            <span className="badge bg-info">{rowData.brandOrderCount}</span><br />
          </div>);
      }
    },
    {
      targets: 4,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="badge bg-info">{rowData.productBrandCount}</span><br />
          </>);
      }
    },
    {
      targets: 5,
      orderable: false,
      className: "text-center",

      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <DrSweetAlertButton
            className={`ms-2 btn btn-wave waves-effect waves-light shadow btn-sm  ${rowData.isBlocked ? "btn-warning" : "btn-danger"}`}
            buttonText={rowData.isBlocked ? t('unblock') : t('block')}
            buttonIcon={rowData.isBlocked ? <i className="fe fe-check-square me-2"></i> : <i className="fe fe-x-square me-2"></i>}

            onCloseConfirmCallBack={(value) => {
              if (value) {
                dispatch(withActionPromise(saveProductBrandBlock({
                  customerMainSellerId: selectedStore.customerMainSellerId,
                  productBrandIds: [rowData.id],
                  isBlocked: !rowData.isBlocked
                })));
              }
            }}
            sweetAlertVariant={sweetAlertVariants.areYouSureConfirm}
          >
          </DrSweetAlertButton>
        );
      }
    }
  ];

  const selectedRowsOperationsCallBack = (checkedValues, selectedOperation, filterType) => {

    if (selectedOperation === "0" || selectedOperation === "1") //export
    {
      var data = productsBrands.filter(item1 =>
        !!checkedValues.find(item2 => item1.id === item2));

      if (selectedOperation === "0" && data?.length > 0) {
        ExportToExcel({ excelData: data, fileName: "brands" });
      }
      else if (selectedOperation === "1" && data?.length > 0) {
        const tableColumn = ["BRAND NAME", "ESTIMATED PROFIT", "ORDER COUNT", "PRODUCT COUNT"];
        // define an empty array of rows
        const tableRows = [];

        // for each ticket pass all its data into an array
        data.forEach(brand => {
          const brandData = [
            brand.brandName,
            "$" + Number(brand.estimatedProfit).toFixed(2),
            brand.brandOrderCount,
            brand.productBrandCount
          ];
          // push each tickcet's info into a row
          tableRows.push(brandData);
        });
        generatePdfReport({ columns: tableColumn, dataSource: tableRows, title: "Product Brands", orientation: 'l', reportName: "product_brands" });

      }
    }
    if (selectedOperation === "16" || selectedOperation === "17") {

      var requestData = {
        customerMainSellerId: selectedStore.customerMainSellerId,
        productBrandIds: [
          ...checkedValues
        ],
        isBlocked: selectedOperation === "16" ? true : false
      }
      dispatch(withActionPromise(saveProductBrandBlock(requestData)));
    }

  }
  const refreshClickedCallBack = () => {
    if (selectedStore?.customerMainSellerId) {
      dispatch(withActionPromise(getProductsBrands(selectedStore?.customerMainSellerId)));
    }
  };

  return (
    <>
      <DrBreadCrump
        breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.inventory'), navigationPath: "/inventory" },
        { navigationText: i18next.t('constants:breadCrumps.brandList'), navigationPath: "" }]} >
      </DrBreadCrump>
      <div id="inventoryTable" className="row mb-5">
        <div className="col-lg-12 col-xl-12 col-md-12">
          <div className="card custom-card">
            <div className="card-body horizontal-scrollable">
              <>
                <Formik
                  initialValues={initialValues}
                  validateOnBlur={true}
                  onSubmit={handleSubmit}
                  enableReinitialize={true}
                  validateOnMount={true}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isValid,
                    setFieldValue
                  }) => (
                    <form onSubmit={(e) => { e.preventDefault(); handleSubmit(values, { resetForm: () => setFieldValue('word', '') }); }}>
                      <div className="row row-md row-lg justify-content-center">
                        <div className="col-12 px-0">
                          <div className="form-group">
                            <DrInput
                              value={values.word}
                              className="form-control"
                              placeholder={t('brandName')}
                              name="word"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              validationText={errors.word}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-md row-lg justify-content-end mb-3 mt-2">
                        <div className="col-auto px-0">
                          <button
                            className={`btn btn-primary btn-wave waves-effect waves-light shadow ${isValid && values.word !== '' ? "" : "disabled"}`}
                            type="submit"
                          >
                            {t('addBrand')}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
                <DrDataTable
                  data={productsBrands}
                  columns={columns}
                  columnDefs={columnDefs}
                  doesHaveBorder={false}
                  headerSearch={true}
                  // headerSelectbox={true}
                  minWidth="850px"
                  search={true}
                  order={[[1, 'asc']]}
                  isMultiSelectEnabled={true}
                  detailedSearchChildren={
                    <DrPageFilter
                      refreshClickedCallBack={refreshClickedCallBack}
                      filterType={pageFilterTypes.brands}
                      selectedRowsOperationsCallBack={selectedRowsOperationsCallBack}
                    >
                    </DrPageFilter>
                  }
                />
              </>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
export default BrandListPage