import moment from 'moment';
import types from '../actions/settings.actions';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CLEAR_SETTINGS:
            return { ...initialState };
        case types.SET_LANGUAGE:
            return { ...state, language: action.payload };
        case types.SET_THEME:
            return { ...state, themeColor: action.payload };
        case types.SET_LAST_VISITED_MARKET:
            return { ...state, lastVisitedMarketId: action.payload };
        case types.SET_LAST_VISITED_PAGE:
            return { ...state, lastVisitedPage: action.payload };
        case types.SET_SELECTED_STORE_CURRENCY_CODE_COEFFICIENT:
            return {
                ...state, "selectedStoreCurrencyType": {
                    ...state.pageSelections,
                    ...action.payload
                }
            };
        case types.SET_SEARCH_TASK_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "reprizerPageSelections": {
                        ...state.pageSelections.reprizerPageSelections,
                        ...action.payload
                    }
                }
            };
        case types.SET_ORDER_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "ordersPageSelections": {
                        ...state.pageSelections.ordersPageSelections,
                        ...action.payload
                    }
                }
            };
        case types.SET_HOME_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "homePageSelections": {
                        ...state.pageSelections.homePageSelections,
                        ...action.payload
                    }
                }
            };
        case types.SET_WAREHOUSE_ORDER_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "warehouseOrdersPageSelections": {
                        ...state.pageSelections.warehouseOrdersPageSelections,
                        ...action.payload
                    }
                }
            };
        case types.SET_WAREHOUSE_HOME_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "warehouseHomePageSelections": {
                        ...state.pageSelections.warehouseHomePageSelections,
                        ...action.payload
                    }
                }
            };
        case types.SET_MY_STORE_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "myStorePageSelections": {
                        ...state.pageSelections.myStorePageSelections,
                        ...action.payload
                    }
                }
            };
        case types.SET_MY_WALLET_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "walletPageSelections": {
                        ...state.pageSelections.walletPageSelections,
                        ...action.payload
                    }
                }
            };
        case types.SET_INVENTORY_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "inventoryPageSelections": {
                        ...state.pageSelections.inventoryPageSelections,
                        ...action.payload
                    }
                }
            };
        case types.SET_PRODUCT_DETAIL_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "productDetailPageSelections": {
                        ...state.pageSelections.productDetailPageSelections,
                        ...action.payload
                    }
                }
            };
        case types.CLEAR_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...initialState.pageSelections
                }
            };
        default:
            return state;
    }
};

export default reducer;

const initialState = {
    language: "tr",
    themeColor: "light",
    lastVisitedMarketId: "6fdac382-b16f-4705-b4c3-1f1c9b4505f4",
    lastVisitedPage: "/home",
    selectedStoreCurrencyType: {
        currencyCode: "USD",
        currencyCoefficient: 1
    },
    userSelectedCurrencyType: {
        currencyCode: "USD",
        currencyCoefficient: 1
    },
    pageSelections: {
        homePageSelections: {
            dateInterval: [
                // "2024-01-01T00:00:00Z",
                moment.utc(moment.utc(), "YYYY/MM/DD").startOf('day').format("YYYY/MM/DD"),
                null
            ],
            graphicType: 0
        },
        ordersPageSelections: {
            orderStatus: 0,
            dateInterval: [
                moment.utc(moment.utc(), "YYYY/MM/DD").startOf('day').format("YYYY/MM/DD"),
                null
            ]
        },
        warehouseOrdersPageSelections: {
            dateInterval: [
                moment.utc(moment.utc(), "YYYY/MM/DD").startOf('day').format("YYYY/MM/DD"),
                null
            ],
            orderStatus: 0,
            marketOption: null,
            tableSkip: 0,
            tableLength: 20,
            pagelength: 20,
            sortingInfo: ["8", "updatedDate", "desc"]
        },
        warehouseHomePageSelections: {
            dateInterval: [
                moment.utc(moment.utc(), "YYYY/MM/DD").startOf('day').format("YYYY/MM/DD"),
                null
            ],
            marketOption: null
        },
        reprizerPageSelections: {
            taskStatus: "0",
            taskType: "0"
        },
        myStorePageSelections: {
            tabId: "mystoreTab",
            warehouseMarketOption: "",
            pricingMarketOption: ""
        },
        walletPageSelections: {
            dateInterval: [
                moment.utc(moment.utc(), "YYYY/MM/DD").startOf('day').format("YYYY/MM/DD"),
                null
            ],
            transactionSummaryTypeOption: "0"
        },
        inventoryPageSelections: {
            inventoryStatus: 4,
            tableSkip: 0,
            tableLength: 20,
            detailedSearchShownAccordions: {
                panelSalesMarket: null,
                panelSourceMarket: null,
                panelCommon: null
            },
            pagelength: 20,
            sortingInfo: ["8", "updatedDate", "desc"]

        },
        productDetailPageSelections: {
            productDetailShownAccordions: {
                panelDBSource: null,
                panelKeepaSource: null
            },
        }
    },
};