import React, { useEffect, useState } from 'react'
import { DrBreadCrump, DrButtonDropdown, DrInput, DrReadMore, DrSweetAlertButton, DrTimeLine, DrPDFViewer } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { deleteOrderNote, getOrderDetailSaleSummary, getOrderItem, getOrderNotes, getOrderTrackingStatus, saveOrderFlag, saveOrderNote, updateOrderItemAmazonOrderNumber, getInvoice, getOrderItemByOrderNumber, updateOrderDetailSaleSummary } from '../../state-management/actions/orders.actions';
import { clearActiveBuyoutCarrierSelectionModal, setActiveBuyoutCarrierSelectionModal, setActiveCreateLabelModal, setActivePictureModal, withActionPromise } from '../../state-management/actions/app.actions';
import * as moment from 'moment';
import i18next from 'i18next';
import { useNavigate, useParams } from 'react-router-dom';
import noImage from '../../assets/img/pngs/no-image.png'
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { buttonDropdownDirection, buttonVariants, modals, siteExtensions, sweetAlertVariants, timelineVariants } from '../../utils/constants';
// import { trackingStatusMockData as orderTrackingStatuses } from '../../utils/mocks-data';
import { toastr } from 'react-redux-toastr';
import { getShippings, getWarehouseShippings, getWarehouses } from '../../state-management/actions/warehouses.actions';
import { flagOptions } from '../../components/hoc/Constants/dr-constants.component';
import Restricted from '../../security/Restricted';
import classNames from 'classnames';
import { clearShipStationData } from '../../state-management/actions/shipstation.actions';


function OrderDetailPage() {
  const { REACT_APP_ROLES_SUPERADMIN, REACT_APP_ROLES_ADMIN, REACT_APP_ROLES_SUSPENDED_ADMIN, REACT_APP_ROLES_STORE, REACT_APP_ROLES_ORDERS } = process.env;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('orderDetail');
  const { orderId } = useParams();
  const selectedOrderDetail = useSelector(state => state.orders.selectedOrder);
  const orderDetailSaleSummary = useSelector(state => state.orders.orderDetailSaleSummary);
  const orderTrackingStatuses = useSelector(state => state.orders.orderTrackingStatuses);
  const selectedStore = useSelector(state => state.stores.selectedStore);
  const [orderDetailData, setOrderDetailData] = useState(null);
  const allWarehouses = useSelector(state => state.warehouses.allWarehouses);
  const allWarehouseShippings = useSelector(state => state.warehouses.allWarehouseShippings);
  const [warehouseData, setWarehouseData] = useState(null);
  const selectedStoreCurrencyType = useSelector(state => state.settings?.selectedStoreCurrencyType);
  const orderNotes = useSelector(state => state.orders.orderNotes);
  const invoice = useSelector(state => state.orders.invoice);
  const [showPDF, setShowPDF] = useState(false);
  const customerInfo = useSelector(state => state.customer.customerInfo);
  const customerPackageInfo = useSelector(state => state.customer.customerPackageInfo);
  const [sellerRefundValue, setSellerRefundValue] = useState(0);
  const [feeRefundValue, setFeeRefundValue] = useState(0);

  const [buyerRefundValue, setBuyerRefundValue] = useState(0);
  const [returnedCustomsDutyPriceValue, setReturnedCustomsDutyPriceValue] = useState(0);

  useEffect(() => {
    dispatch(withActionPromise(getShippings()));
    dispatch(withActionPromise(getWarehouses()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orderId) {
      var orderNumberRegex = /^\d{3}-\d{7}-\d{7}$/;
      if (orderNumberRegex.test(orderId)) {
        dispatch(withActionPromise(getOrderItemByOrderNumber({ orderNumber: orderId, customerMainSellerId: selectedStore.customerMainSellerId })));
      }
      else {

        dispatch(withActionPromise(getOrderItem({ orderId: orderId })));
        if (selectedStore?.customerMainSellerId) {
          dispatch(withActionPromise(getInvoice({ customerMainSellerId: selectedStore.customerMainSellerId, orderId: orderId })));
        }
      }
    }

    if (selectedStore?.customerMainSellerId) {
      dispatch(withActionPromise(getWarehouseShippings({ customerMainSellerId: selectedStore.customerMainSellerId })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStore?.customerMainSellerId, orderId]);

  useEffect(() => {

    if (selectedOrderDetail?.orderDetailList?.length > 0) {
      dispatch(withActionPromise(getOrderDetailSaleSummary({ orderId: selectedOrderDetail?.orderDetailList[0].orderId })));
      dispatch(withActionPromise(getOrderTrackingStatus({ orderId: selectedOrderDetail?.orderDetailList[0].orderId })));
      dispatch(withActionPromise(getOrderNotes(selectedOrderDetail?.orderDetailList[0].orderId)));
      if (selectedStore?.customerMainSellerId) {
        dispatch(withActionPromise(getInvoice({ customerMainSellerId: selectedStore.customerMainSellerId, orderId: selectedOrderDetail?.orderDetailList[0].orderId })));
      }
    }

    var data = {

      orderNo: selectedOrderDetail?.orderNumber || "",
      orderStatus: selectedOrderDetail?.orderStatus || "",
      totalPrice: selectedOrderDetail?.ordertotalCurrencyCode + " " + selectedOrderDetail?.ordertotalAmount || "",

      orderHistory: {
        purchaseDate: selectedOrderDetail?.purchaseDate || "",
        salesChannel: selectedOrderDetail?.salesChannel || "",
        shipmentServiceLevelCategory: selectedOrderDetail?.shipmentServiceLevelCategory || "",
        earliestShipDate: selectedOrderDetail?.earliestShipDate || "",
        latestShipDate: selectedOrderDetail?.latestShipDate || "",
        earliestDeliveryDate: selectedOrderDetail?.earliestDeliveryDate || "",
        latestDeliveryDate: selectedOrderDetail?.latestDeliveryDate || ""
      },
      shippingDetails: {
        buyerName: selectedOrderDetail?.shippingAddress?.name || "",
        phone: selectedOrderDetail?.shippingAddress?.phone || "",
        addressLine1: selectedOrderDetail?.shippingAddress?.addressLine1 || "",
        addressLine2: selectedOrderDetail?.shippingAddress?.addressLine2 || "",
        city: selectedOrderDetail?.shippingAddress?.city || "",
        stateOrRegion: selectedOrderDetail?.shippingAddress?.stateOrRegion || "",
        countryCode: selectedOrderDetail?.shippingAddress?.countryCode || "",
        postalCode: selectedOrderDetail?.shippingAddress?.postalCode || ""
      },
      orderDetailList: selectedOrderDetail?.orderDetailList || null,
      buyerSellerOrderNumber: selectedOrderDetail?.buyerSellerOrderNumber || "",
      orderFlag: selectedOrderDetail?.orderFlag || null
    }

    setOrderDetailData(data);

    var warehouseData = {

      wareHouseName: allWarehouses?.find(x => x.id === selectedOrderDetail?.wareHouseId)?.wareHouseName || "-",
      addressLine1: allWarehouses?.find(x => x.id === selectedOrderDetail?.wareHouseId)?.addressLine1 || "-",
      addressLine2: allWarehouses?.find(x => x.id === selectedOrderDetail?.wareHouseId)?.addressLine2 || "-",
      city: allWarehouses?.find(x => x.id === selectedOrderDetail?.wareHouseId)?.city || "-",
      district: allWarehouses?.find(x => x.id === selectedOrderDetail?.wareHouseId)?.district || "-",
      stateOrRegion: allWarehouses?.find(x => x.id === selectedOrderDetail?.wareHouseId)?.stateOrRegion || "-",
      postalCode: allWarehouses?.find(x => x.id === selectedOrderDetail?.wareHouseId)?.postalCode || "-",
      phone: allWarehouses?.find(x => x.id === selectedOrderDetail?.wareHouseId)?.phone || "-",
      warehouseShippingName: allWarehouseShippings?.find(x => x.wareHouseId === selectedOrderDetail?.wareHouseId)?.shippings?.find(x =>
        x.carrierCode === selectedOrderDetail?.carrierCode
        && x.carrierServiceCode === selectedOrderDetail?.carrierServiceCode)?.shippingName || "",
      estimatedDeliveryDays: (moment(selectedOrderDetail?.latestShipDate).isValid() && moment(selectedOrderDetail?.latestDeliveryDate).isValid() && moment(selectedOrderDetail?.purchaseDate).isValid())
        ? moment(selectedOrderDetail?.latestShipDate).startOf("day").diff(moment(selectedOrderDetail?.purchaseDate).startOf("day"), 'days') + " - "
        + moment(selectedOrderDetail?.latestDeliveryDate).startOf("day").diff(moment(selectedOrderDetail?.latestShipDate).startOf("day"), 'days')
        : "-",

      totalWarehouseCost: orderDetailSaleSummary?.warehousePrice || "0",
      trackingNumber: orderTrackingStatuses?.length > 0 ? (orderTrackingStatuses[orderTrackingStatuses.length - 1].trackingNumber || "-") : "-"

    }
    setWarehouseData(warehouseData);


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrderDetail]);

  const initialOrderSummaryData = {
    totalSalePrice: orderDetailSaleSummary?.orderTotalAmount || 0,
    salePrice: orderDetailSaleSummary?.sellingPrice || 0,
    saleShippingPrice: orderDetailSaleSummary?.fastShippingPrice || 0,
    saleTax: orderDetailSaleSummary?.totalTax || 0,

    amazonFee: orderDetailSaleSummary?.amazonFee || 0,

    totalCost: orderDetailSaleSummary?.progressPayment || 0,
    totalWarehouseCost: 0,
    shipFromWarehousePrice: orderDetailSaleSummary?.warehousePrice || 0,
    returnToWarehousePrice: 0,
    extraWarehouseCost: 0,

    totalProductCost: (orderDetailSaleSummary?.sellingPrice || 0) + (orderDetailSaleSummary?.shippingCost || 0),
    productPrice: orderDetailSaleSummary?.sellingPrice || 0,
    productShippingPrice: orderDetailSaleSummary?.shippingCost || 0,

    // feeRefund: 0,
    // sellerRefund: sellerRefundValue || 0,
    // buyerRefund: 0,
    // returnedCustomsDutyPrice: 0,
    productTax: orderDetailSaleSummary?.totalTax || 0,

    creditToAccountPrice: 0,

    profitAmount: (orderDetailSaleSummary?.profitPrice - (sellerRefundValue + feeRefundValue) + (buyerRefundValue + returnedCustomsDutyPriceValue)) || 0,
    profitPercent: (orderDetailSaleSummary?.profitPercent * (orderDetailSaleSummary?.profitPrice > 0 ? ((orderDetailSaleSummary?.profitPrice - (sellerRefundValue + feeRefundValue) + (buyerRefundValue + returnedCustomsDutyPriceValue)) / (orderDetailSaleSummary?.profitPrice)) : 1)) || 0,

  }

  const flagOptionClickCallBack = (value, label) => {
    dispatch(saveOrderFlag({
      orderIdList: [
        orderId
      ],
      orderFlag: value === 0 ? null : value
    }));
  };


  const handleDropdownOptionClick = (value) => {
    switch (value) {
      case "1":
        // Handle sending feedback email
        break;
      case "2":
        navigate('/message-detail/' + orderId);
        break;
      case "3":
        window.open(`https://sellercentral.amazon.${siteExtensions.find(x => x.countryCode === (selectedStore?.countryCode || "us"))?.extension}/orders-v3/order/${orderId}`, "_blank", "noreferrer");
        break;
      case "4":
        setShowPDF(true);
        break;
      default:
        break;
    }
  };

  var secondRowFirstColumnClass = classNames({
    'col-xxl-8 col-lg-8 col-xl-8 col-md-12 col-sm-12 d-flex flex-column': (customerInfo?.role?.includes(REACT_APP_ROLES_SUPERADMIN) || customerInfo?.role?.includes(REACT_APP_ROLES_ADMIN) || customerInfo?.role?.includes(REACT_APP_ROLES_SUSPENDED_ADMIN)),
    'col-12 d-flex flex-column': !(customerInfo?.role?.includes(REACT_APP_ROLES_SUPERADMIN) || customerInfo?.role?.includes(REACT_APP_ROLES_ADMIN) || customerInfo?.role?.includes(REACT_APP_ROLES_SUSPENDED_ADMIN))
  });
  var secondRowSecondColumnClass = classNames({
    'col-auto col-xxl-4 col-lg-4 col-xl-4 col-md-12 col-sm-12 d-flex flex-column': (customerInfo?.role?.includes(REACT_APP_ROLES_SUPERADMIN) || customerInfo?.role?.includes(REACT_APP_ROLES_ADMIN) || customerInfo?.role?.includes(REACT_APP_ROLES_SUSPENDED_ADMIN)),
    'col-12 d-flex flex-column': !(customerInfo?.role?.includes(REACT_APP_ROLES_SUPERADMIN) || customerInfo?.role?.includes(REACT_APP_ROLES_ADMIN) || customerInfo?.role?.includes(REACT_APP_ROLES_SUSPENDED_ADMIN))
  });

  return (
    <>
      <DrBreadCrump
        breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.orders'), navigationPath: "/orders" },
        { navigationText: i18next.t('constants:breadCrumps.orderDetails'), navigationPath: "" }]} >
      </DrBreadCrump>

      <div className="row justify-content-center justify-content-sm-between justify-content-md-between justify-content-lg-between justify-content-xl-between justify-content-xxl-between mb-1">
        <div className="col-auto my-1 px-0">
          <div className="row justify-content-center">
            {orderDetailData?.buyerSellerOrderNumber &&
              <div className="col-auto my-1">
                <button className="btn btn-teal btn-wave waves-effect waves-light shadow"
                  onClick={(e) => {
                    e.preventDefault();
                    navigator.clipboard.writeText(orderDetailData?.buyerSellerOrderNumber);
                    toastr.info("Info", orderDetailData?.buyerSellerOrderNumber + " is copied to clipboard", { timeOut: 1000 })
                  }}>
                  {orderDetailData?.buyerSellerOrderNumber}
                  <i className="ms-2 bx bxs-copy bx-xs bx-burst-hover fw-bold text-white cursor-pointer" > </i>
                </button>

              </div>
            }

          </div>
        </div>
        <div className="col-auto my-1 pe-xl-0 pe-lg-0 pe-md-0 pe-sm-0">
          <div className="row justify-content-center">
            <Restricted allowedTo={[REACT_APP_ROLES_STORE]}>
              <div className="col-auto my-1">
                <button className={`ms-2 btn btn-teal btn-wave waves-effect waves-light shadow`}

                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(clearShipStationData());
                    dispatch(setActiveCreateLabelModal({
                      name: modals.createLabel, isGlobal: true,
                      source: orderId
                    }));

                  }}>
                  {/* {rowData.trackingNumber ? "Re-Create Label" : "Create Label"} */}
                  Create Label
                </button>
              </div>
            </Restricted>

            <Restricted allowedTo={[
              REACT_APP_ROLES_SUPERADMIN,
              REACT_APP_ROLES_ADMIN,
              REACT_APP_ROLES_ORDERS
            ]}>
              {(orderDetailData?.orderDetailList?.length > 0 && orderDetailData?.orderNo && selectedStore?.isRefreshTokenValid === true) &&
                <>
                  <div className="col-auto my-1">
                    <button className={`btn btn-teal position-relative shadow cursor-pointer ${!orderDetailData?.isOrderable ? "disabled" : ""}`}
                      onClick={(e) => {

                        e.preventDefault();
                        dispatch(setActiveBuyoutCarrierSelectionModal({
                          name: modals.buyoutCarrierSelection, isGlobal: true, title: "", source: selectedOrderDetail?.orderDetailList[0].orderId,
                          carrierSelectionCallback: (response) => {
                            dispatch(clearActiveBuyoutCarrierSelectionModal());
                          }
                        }));
                      }}
                    >
                      {orderDetailData.isOrderable === false ? "Tamamlandı" : "Satın Al"}
                      <span class="position-absolute top-0 start-100 translate-middle text-white badge rounded-pill bg-danger pulse pulse-danger">
                        {orderDetailData?.orderDetailList.reduce((total, product) => product.quantityOrdered + total, 0)}
                      </span>
                    </button>

                  </div>
                </>

              }
            </Restricted>
            <div className="col-auto my-1 ps-xl-0 ps-lg-0 ps-md-0 ps-sm-0">

              <DrButtonDropdown className="shadow" options={flagOptions}
                optionClickCallBack={flagOptionClickCallBack}
                selectedValue={orderDetailData?.orderFlag}
                variant={buttonVariants.light}
              >
              </DrButtonDropdown>

            </div>
            <Restricted allowedTo={[
              REACT_APP_ROLES_SUPERADMIN,
              REACT_APP_ROLES_ADMIN,
              REACT_APP_ROLES_SUSPENDED_ADMIN,
              REACT_APP_ROLES_ORDERS
            ]}>
              <div className="col-auto my-1 ps-xl-0 ps-lg-0 ps-md-0 ps-sm-0">
                <DrButtonDropdown className="shadow"
                  options={[
                    { value: "1", label: "Send Feedback Email", icon: <i className="fe fe-mail me-2"></i> },
                    { value: "2", label: "Send Message", icon: <i className="fe fe-send me-2"></i> },
                    { value: "3", label: "SellerCentral'da Görüntüle", icon: <i className="fe fe-eye me-2"></i> },
                    { value: "4", label: "Fatura İndir", icon: <i className="fe fe-download me-2"></i> }
                  ]}
                  optionClickCallBack={handleDropdownOptionClick}
                  fixedTextKey="options"
                  variant={buttonVariants.light}
                  direction={buttonDropdownDirection.vertical}
                // selectedVariant={buttonVariants.teal}
                >
                </DrButtonDropdown>
              </div>
            </Restricted>
          </div>
        </div>
      </div >

      <div className="row row-md row-lg justify-content-center">
        <div className="col col-xxl-5 col-lg-5 col-xl-5 col-md-12 col-sm-12 d-flex flex-column">
          <div className="row flex-grow-shrink">
            <div className="card custom-card">
              <div className="card-body" style={{ minWidth: '260px' }}>
                <div className="row">
                  <div className="col-4 col-md-4 col-sm-4">
                    <p className="fs-13 fw-semibold mb-1">Gönderim Tarihi:</p>
                  </div>
                  <div className="col-8 col-md-8 col-sm-8">
                    <p className="fs-13 text-muted mb-1">{moment(orderDetailData?.orderHistory.earliestShipDate).format('LLLL')} to  {moment(orderDetailData?.orderHistory.latestShipDate).format('LLLL')}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 col-md-4 col-sm-4">
                    <p className="fs-13 fw-semibold mb-1">Teslim Edilme Tarihi:</p>
                  </div>
                  <div className="col-8 col-md-8 col-sm-8">
                    <p className="fs-13 text-muted mb-1">{moment(orderDetailData?.orderHistory.earliestDeliveryDate).format('LLLL')} to  {moment(orderDetailData?.orderHistory.latestDeliveryDate).format('LLLL')}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 col-md-4 col-sm-4">
                    <p className="fs-13 fw-semibold mb-1">Satın Alma Tarihi:</p>
                  </div>
                  <div className="col-8 col-md-8 col-sm-8">
                    <p className="fs-13 text-muted mb-1">{moment(orderDetailData?.orderHistory.purchaseDate).format('LLLL')}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 col-md-4 col-sm-4">
                    <p className="fs-13 fw-semibold mb-1">Kargo Hizmeti:</p>
                  </div>
                  <div className="col-8 col-md-8 col-sm-8">
                    <p className="fs-13 text-muted mb-1">{orderDetailData?.orderHistory.shipmentServiceLevelCategory}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 col-md-4 col-sm-4">
                    <p className="fs-13 fw-semibold mb-1">Fulfillment:</p>
                  </div>
                  <div className="col-8 col-md-8 col-sm-8">
                    <p className="fs-13 text-muted mb-1">Seller</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 col-md-4 col-sm-4">
                    <p className="fs-13 fw-semibold mb-1">Satış Mağazası:</p>
                  </div>
                  <div className="col-8 col-md-8 col-sm-8">
                    <p className="fs-13 text-muted mb-1">{orderDetailData?.orderHistory.salesChannel}</p>
                  </div>
                </div>


              </div>
            </div>
          </div>
          <div className="row flex-grow-shrink">
            <div className="card custom-card">
              <div className="card-body">

                <div className="row">
                  <div className="col-4 col-md-4 col-sm-4">
                    <p className="fs-13 fw-semibold mb-1">Alıcı adı:</p>
                  </div>
                  <div className="col-8 col-md-8 col-sm-8">
                    <p className="fs-13 text-muted mb-1">{orderDetailData?.shippingDetails.buyerName || ""}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 col-md-4 col-sm-4">
                    <p className="fs-13 fw-semibold mb-1">Telefon:</p>
                  </div>
                  <div className="col-8 col-md-8 col-sm-8">
                    <p className="fs-13 text-muted mb-1">{orderDetailData?.shippingDetails.phone || ""}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 col-md-4 col-sm-4">
                    <p className="fs-13 fw-semibold mb-1">Adres 1:</p>
                  </div>
                  <div className="col-8 col-md-8 col-sm-8">
                    <p className="fs-13 text-muted mb-1">{orderDetailData?.shippingDetails.addressLine1 || ""}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 col-md-4 col-sm-4">
                    <p className="fs-13 fw-semibold mb-1">Adres 2:</p>
                  </div>
                  <div className="col-8 col-md-8 col-sm-8">
                    <p className="fs-13 text-muted mb-1">{orderDetailData?.shippingDetails.addressLine2 || ""}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 col-md-4 col-sm-4">
                    <p className="fs-13 fw-semibold mb-1">Şehir:</p>
                  </div>
                  <div className="col-8 col-md-8 col-sm-8">
                    <p className="fs-13 text-muted mb-1">{orderDetailData?.shippingDetails.city || ""}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 col-md-4 col-sm-4">
                    <p className="fs-13 fw-semibold mb-1">Posta kodu:</p>
                  </div>
                  <div className="col-8 col-md-8 col-sm-8">
                    <p className="fs-13 text-muted mb-1">{orderDetailData?.shippingDetails.postalCode || ""}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 col-md-4 col-sm-4">
                    <p className="fs-13 fw-semibold mb-1">Eyalet / İlçe:</p>
                  </div>
                  <div className="col-8 col-md-8 col-sm-8">
                    <p className="fs-13 text-muted mb-1">{orderDetailData?.shippingDetails.stateOrRegion || ""}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 col-md-4 col-sm-4">
                    <p className="fs-13 fw-semibold mb-1">Ülke kodu:</p>
                  </div>
                  <div className="col-8 col-md-8 col-sm-8">
                    <p className="fs-13 text-muted mb-1">{orderDetailData?.shippingDetails.countryCode || ""}</p>
                  </div>
                </div>
                <Restricted allowedTo={[
                  REACT_APP_ROLES_SUPERADMIN,
                  REACT_APP_ROLES_ADMIN,
                  REACT_APP_ROLES_SUSPENDED_ADMIN,
                  REACT_APP_ROLES_ORDERS
                ]}>
                  <div className="row justify-content-end">
                    <div className="col-auto">
                      <a className="btn ripple btn-info" target="_blank" rel="noreferrer"
                        href={"https://sellercentral.amazon." + siteExtensions.find(x => x.countryCode === (selectedStore?.countryCode || "us"))?.extension + "/home"}
                      >
                        <i className="fe fe-forward me-1"></i>
                        Adres Bilgilerini Topla
                      </a>
                    </div>
                  </div>
                </Restricted>
              </div>
            </div>
          </div>
        </div>
        <div className="col col-xxl-7 col-lg-7 col-xl-7 col-md-12 col-sm-12 d-flex flex-column">
          <div className="row ps-lg-3 flex-grow-shrink" >
            <div className="card custom-card px-0">
              <div className="card-body d-flex flex-column">

                <ul className="nav nav-pills mb-3 nav-justified tab-style-2 d-sm-flex d-block" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className={`nav-link active`}
                      data-bs-toggle="pill" data-bs-target="#warehouseDataTab" type="button" role="tab" aria-controls="warehouseDataTab">
                      <i className="bx bxs-store bx-xs ms-1 me-1"></i>
                      Warehouse Info
                    </button>
                  </li>
                  <Restricted allowedTo={[
                    REACT_APP_ROLES_SUPERADMIN,
                    REACT_APP_ROLES_ADMIN,
                    REACT_APP_ROLES_SUSPENDED_ADMIN,
                    REACT_APP_ROLES_ORDERS
                  ]}>
                    <li className="nav-item" role="presentation">
                      <button className={`nav-link`}
                        data-bs-toggle="pill" data-bs-target="#warehouseMessagesTab" type="button" role="tab" aria-controls="warehouseMessagesTab">
                        <i className="bx bxs-message-square-dots bx-xs ms-1 me-1"></i>
                        Warehouse Messages
                      </button>
                    </li>
                  </Restricted>
                </ul>
                <div className="tab-content flex-grow-shrink">
                  <div id="warehouseDataTab" className={`tab-pane bg-primary bg-opacity-05 h-100 active`}>
                    <div className="row">
                      <div className="col-auto">
                        <p className="fs-16 mb-1">Paket No:</p>
                      </div>
                      <div className="col-auto ps-0">
                        <label>
                          <span className="fs-16 text-default fw-bold mb-1">{orderDetailData?.orderNo}</span>
                          <span onClick={(e) => {
                            e.preventDefault();
                            navigator.clipboard.writeText(orderDetailData?.orderNo);
                            toastr.info("Info", orderDetailData?.orderNo + " is copied to clipboard", { timeOut: 1000 })
                          }}>
                            <i className="bx bxs-copy bx-xs bx-burst-hover fw-bold text-info cursor-pointer" > </i>
                          </span>
                        </label>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="row" >
                      <div className="col-6">
                        <div className="row">
                          <div className="col-auto my-auto">
                            {/* <p className="fs-13 fw-semibold mb-1">Warehouse:</p> */}
                            <i className="bx bxs-store-alt bx-sm text-primary fw-bold"></i>
                          </div>
                          <div className="col-auto my-auto">
                            <p className="fs-13 text-muted mb-1">{warehouseData?.wareHouseName}</p>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-auto my-auto">
                            {/* <p className="fs-13 fw-semibold mb-1">Warehouse:</p> */}
                            <i className="bx bxs-truck bx-sm text-primary fw-bold"></i>
                          </div>
                          <div className="col-auto my-auto">
                            <p className="fs-13 text-muted mb-1">{warehouseData?.warehouseShippingName}</p>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-auto my-auto">
                            {/* <p className="fs-13 fw-semibold mb-1">Warehouse:</p> */}
                            <i className="bx bxs-calendar-check bx-sm text-primary fw-bold"></i>
                          </div>
                          <div className="col-auto my-auto">
                            <p className="fs-13 text-muted mb-1">{warehouseData?.estimatedDeliveryDays}</p>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-auto my-auto">
                            {/* <p className="fs-13 fw-semibold mb-1">Warehouse:</p> */}
                            <i className="bx bxs-navigation bx-sm text-primary fw-bold"></i>
                          </div>
                          <div className="col-auto my-auto">
                            <p className="fs-13 text-muted mb-1">{warehouseData?.trackingNumber}</p>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-auto my-auto">
                            {/* <p className="fs-13 fw-semibold mb-1">Warehouse:</p> */}
                            <i className="bx bx-money bx-sm text-primary fw-bold"></i>
                          </div>
                          <div className="col-auto my-auto">
                            <p className="fs-13 text-muted mb-1">{selectedStoreCurrencyType.currencyCode} {warehouseData?.totalWarehouseCost}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="row">
                          <div className="col-auto my-auto">
                            {/* <p className="fs-13 fw-semibold mb-1">Warehouse:</p> */}
                            <i className="bx bxs-phone bx-sm text-primary fw-bold"></i>
                          </div>
                          <div className="col-auto my-auto">
                            <p className="fs-13 text-muted mb-1">{warehouseData?.phone}</p>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-auto my-auto">
                            <i className="bx bxs-map-pin bx-sm text-primary fw-bold"></i>
                          </div>
                          <div className="col-auto my-auto">
                            <p className="fs-13 text-muted text-wrap mb-1"> {warehouseData?.wareHouseName + " " + warehouseData?.addressLine1 + " " + warehouseData?.postalCode + (warehouseData?.addressLine1 ? ", " : "") + warehouseData?.district + " " + warehouseData?.stateOrRegion + " " + warehouseData?.phone}</p>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div id="warehouseMessagesTab" className={`tab-pane bg-warning bg-opacity-10 h-100`}>
                    hello
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row row-md row-lg justify-content-center">
        <div className={secondRowFirstColumnClass}>
          {orderDetailData?.orderDetailList?.length > 0 && orderDetailData?.orderDetailList.map((product, index) => {
            return (

              <div key={"order_item_" + index + "_" + product.asin} className="row row-md row-lg justify-content-sm-center justify-content-md-center justify-content-center">

                <div className="card custom-card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-xxl-9 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                        <div className="row">
                          <div className="col-12">
                            {/* <h5 className="mb-3">{product.title}</h5> */}
                            <h3 className="card-title fw-bold mb-3">{product.title}</h3>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4 col-md-4 col-sm-4">
                            <p className="fs-13 fw-semibold mb-1">ASIN:</p>
                          </div>
                          <div className="col-8 col-md-8 col-sm-8">
                            <label>

                              <Restricted allowedTo={[
                                REACT_APP_ROLES_SUPERADMIN,
                                REACT_APP_ROLES_ADMIN,
                                REACT_APP_ROLES_SUSPENDED_ADMIN,
                                REACT_APP_ROLES_ORDERS
                              ]}>
                                <span className="text-white fs-13 fw-bold badge bg-info cursor-pointer" data-bs-placement="right" data-bs-toggle="tooltip"
                                  title="Ürün Detayını görmek için tıklyınız!"
                                  onClick={() => {

                                    navigate('/product-detail/' + product.asin);
                                  }}>
                                  {product.asin}
                                </span>
                              </Restricted>
                              <Restricted allowedTo={[
                                REACT_APP_ROLES_STORE
                              ]}>
                                <span className="text-white fs-13 fw-bold badge bg-info cursor-pointer">
                                  {product.asin}
                                </span>
                              </Restricted>

                              <span onClick={(e) => {
                                e.preventDefault();
                                navigator.clipboard.writeText(product.asin);
                                toastr.info("Info", product.asin + " is copied to clipboard", { timeOut: 1000 })
                              }}>
                                <i className="bx bxs-copy bx-xs bx-burst-hover fw-bold text-info cursor-pointer" > </i>
                              </span>

                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4 col-md-4 col-sm-4">
                            <p className="fs-13 fw-semibold mb-1">SKU:</p>
                          </div>
                          <div className="col-8 col-md-8 col-sm-8">
                            <label>
                              <span className="fs-13 text-muted fw-semibold mb-1">{product.sellerSKU}</span>
                              <span onClick={(e) => {
                                e.preventDefault();
                                navigator.clipboard.writeText(product.sellerSKU);
                                toastr.info("Info", product.sellerSKU + " is copied to clipboard", { timeOut: 1000 })
                              }}>
                                <i className="bx bxs-copy bx-xs bx-burst-hover fw-bold text-info cursor-pointer" > </i>
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4 col-md-4 col-sm-4">
                            <p className="fs-13 fw-semibold mb-1">Price:</p>
                          </div>
                          <div className="col-8 col-md-8 col-sm-8">
                            <p className="fs-13 text-muted mb-1">{selectedStoreCurrencyType.currencyCode} {product.itemPriceAmount}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4 col-md-4 col-sm-4">
                            <p className="fs-13 fw-semibold mb-1">Order No:</p>
                          </div>
                          <div className="col-8 col-md-8 col-sm-8">
                            <label>
                              <span className="fs-13 text-muted fw-semibold mb-1">{orderDetailData?.orderNo}</span>
                              <span onClick={(e) => {
                                e.preventDefault();
                                navigator.clipboard.writeText(orderDetailData?.orderNo);
                                toastr.info("Info", orderDetailData?.orderNo + " is copied to clipboard", { timeOut: 1000 })
                              }}>
                                <i className="bx bxs-copy bx-xs bx-burst-hover fw-bold text-info cursor-pointer" > </i>
                              </span>
                            </label>

                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4 col-md-4 col-sm-4 my-auto">
                            <p className="fs-13 fw-semibold mb-1">Amazon Order No:</p>
                          </div>
                          <div className="col-auto">

                            <Formik
                              initialValues={{
                                orderId: product.orderId, buyerSellerOrderNumber: orderDetailData?.buyerSellerOrderNumber || ""
                              }}
                              validateOnBlur={true}
                              // onSubmit={handleSubmit}
                              enableReinitialize={true}
                              validateOnMount={true}
                            >
                              {({
                                isValid,
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                setTouched,
                                setFieldValue
                              }) => (
                                <>
                                  <div className="row">
                                    <div className="col pe-0">
                                      <DrInput className="form-control form-control-sm" placeholder="" name="buyerSellerOrderNumber" type="text"
                                        value={values.buyerSellerOrderNumber || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                    <Restricted allowedTo={[
                                      REACT_APP_ROLES_SUPERADMIN,
                                      REACT_APP_ROLES_ADMIN,
                                      REACT_APP_ROLES_SUSPENDED_ADMIN,
                                      REACT_APP_ROLES_ORDERS
                                    ]}>
                                      <div className="col-auto ps-0">
                                        <button className="btn btn-primary btn-wave waves-effect waves-light shadow btn-sm"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(updateOrderItemAmazonOrderNumber(values));
                                          }}
                                        >
                                          <i className="fe fe-save"></i>
                                        </button>
                                      </div>
                                    </Restricted>
                                  </div>
                                </>

                              )
                              }
                            </Formik>

                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-4 col-md-4 col-sm-4">
                            <p className="fs-13 fw-semibold mb-1">Order Status:</p>
                          </div>
                          <div className="col-8 col-md-8 col-sm-8">
                            <span className="fs-13 text-muted fw-semibold mb-1">{orderDetailData?.orderStatus}</span>
                          </div>
                        </div>

                      </div>
                      <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 px-0">
                        <div className="row justify-content-xxl-end justify-content-xl-end justify-content-lg-end justify-content-center">
                          <div className="col-auto ps-0">
                            {orderDetailData.isOrderable === false &&
                              <span className="badge bg-success bg-opacity-75 p-2 fs-14 shadow" style={{ width: '180px' }}>
                                Satın Alındı
                              </span>

                            }
                          </div>
                        </div>
                        <div className="row justify-content-xxl-end justify-content-xl-end justify-content-lg-end justify-content-center mt-2">
                          <div className="col-auto ps-0">
                            <span className="badge bg-warning bg-opacity-75 p-2 fs-14 shadow" style={{ width: '180px' }}>Kategori: Teknoloji</span>
                          </div>
                        </div>
                        <div className="row justify-content-xxl-end justify-content-xl-end justify-content-lg-end justify-content-center mt-2">
                          <div className="col-auto ps-0 position-relative">
                            <div className="position-absolute bottom-0 end-0 d-flex align-middle m-auto no-select badge rounded-circle bg-danger bg-opacity-50 shadow transform-top-hover text-default text-center"
                              style={{ width: '30px', height: '30px' }}>
                              <p className="m-auto">{product.quantityOrdered}</p>
                            </div>
                            <div className="w-100 h-100 bg-gray-300">
                              <img alt="" className="img-thumbnail h-max cursor-pointer" src={product.imageUrl || noImage} style={{ maxWidth: '180px' }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (product.imageUrl) {
                                    dispatch(setActivePictureModal({ name: modals.picture, isGlobal: true, title: "picture", imgsource: [product.imageUrl] }))
                                  }
                                }} />
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
          }

          {orderDetailData?.orderDetailList?.length < 3 &&
            <div className="row row-md row-lg justify-content-sm-center justify-content-md-center justify-content-center  flex-grow-shrink">
              <div className="card custom-card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <h4 className="mb-3 text-center">Shipment Status</h4>

                      <DrTimeLine variant={timelineVariants.center}
                        timelineSource={(orderTrackingStatuses?.length > 0
                          && orderTrackingStatuses.map(x => ({
                            groupName: x.groupName,
                            title: x.buyerSellerOrderStatus,
                            text: x.CarrierName || x.trackingNumber,
                            time: x.updatedDate

                          }))) || []}
                      ></DrTimeLine>
                      {!orderTrackingStatuses?.length > 0 &&

                        <h6 className="mb-3 text-center">NO TRACKING INFO</h6>
                      }
                    </div>
                  </div>
                </div>
              </div>

            </div>}

          <div className="row row-md row-lg justify-content-sm-center justify-content-md-center justify-content-center flex-grow-shrink">
            <div className="card custom-card">
              <div className="card-body">
                <Formik
                  initialValues={{
                    orderId: orderId,
                    note: ""
                  }}
                  validateOnBlur={true}

                  // validationSchema={storePreferencesSchema}
                  enableReinitialize={true}
                  validateOnMount={true}
                >
                  {({
                    isValid,
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setTouched,
                    setFieldValue,

                  }) => (
                    // <form className="was-validated">
                    <form >
                      <div className="row">
                        <div className="col">
                          <textarea id="message-textarea" className={`form-control w-100`} placeholder="Type your message here..." type="text"
                            style={{ minHeight: '60px', height: "auto" }}
                            value={values?.note}
                            onChange={(e) => {
                              e.preventDefault();
                              var textarea = document.getElementById("message-textarea");
                              textarea.style.height = "";
                              textarea.style.height = Math.min(textarea.scrollHeight, 500) + "px";

                              setFieldValue("note", e.currentTarget.value)
                            }}
                            onBlur={() => { setTouched({ ...touched.note, 'note': true }) }}

                          />
                        </div>
                      </div>
                      <div className="row justify-content-end mt-2">
                        <div className="col-auto">
                          <button className={`btn btn-primary btn-wave waves-effect waves-light shadow  ${isValid ? "" : "disabled"}`}
                            onClick={(e) => {
                              e.preventDefault();
                              if (isValid && values.note?.length > 0) {
                                dispatch(saveOrderNote(values));
                                setFieldValue("note", "");
                              }
                            }}
                          >Add Note</button>
                        </div>
                      </div>
                    </form>
                  )
                  }
                </Formik>

                <div className="row mt-2">
                  {orderNotes && orderNotes.length > 0 &&
                    <div className="table-responsive" >
                      <table className="table table-hover table-bordered border-primary"  >
                        <thead >
                          <tr>
                            <th className="text-center bg-success bg-opacity-10 text-success">Name</th>
                            <th className="text-center bg-success bg-opacity-10 text-success">Note</th>
                            <th className="text-center bg-success bg-opacity-10 text-success">Created Date</th>
                            <th className="text-center bg-success bg-opacity-10 text-success"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderNotes.sort((b, a) => moment(a.createdDate, "YYYY-MM-DDThh:mm:ss").valueOf() - moment(b.createdDate, "YYYY-MM-DDThh:mm:ss").valueOf()).map((orderNote, index) => {

                            return (
                              <tr key={"ordernote_" + index}>
                                <td>
                                  {orderNote.firstName || "Anonymus"} {orderNote.lastName || ""}
                                </td>
                                <td>
                                  <DrReadMore max={80} text={orderNote.note} classes='fs-12'
                                    data-bs-placement="right" data-bs-toggle="tooltip"
                                    title={orderNote.note}
                                  ></DrReadMore>
                                </td>
                                <td className="text-center">
                                  {moment(orderNote.createdDate, "YYYY-MM-DDThh:mm:ss").format("LLLL")}
                                </td>
                                <td className="text-center">
                                  <DrSweetAlertButton
                                    className="btn btn-danger btn-icon btn-sm btn-wave waves-effect waves-light shadow"
                                    buttonText={""}
                                    buttonIcon={<i className="fe fe-trash"></i>}
                                    onCloseConfirmCallBack={(value) => {
                                      if (value) {
                                        dispatch(deleteOrderNote({ noteId: orderNote.id, orderId: orderId }));
                                      }
                                    }}
                                    sweetAlertVariant={sweetAlertVariants.areYouSureConfirm}
                                  >
                                  </DrSweetAlertButton>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <Restricted allowedTo={[
          REACT_APP_ROLES_SUPERADMIN,
          REACT_APP_ROLES_ADMIN,
          REACT_APP_ROLES_SUSPENDED_ADMIN,
          REACT_APP_ROLES_ORDERS
        ]}>
          <div className={secondRowSecondColumnClass}>
            <div className={`row row-md row-lg justify-content-sm-center justify-content-md-center justify-content-center ps-3 ${orderDetailData?.orderDetailList?.length < 3 ? "flex-grow-shrink" : ""}`}>
              <div className="card custom-card">
                <div className="card-body" style={{ height: '100%' }}>

                  <Formik
                    initialValues={initialOrderSummaryData}
                    validateOnBlur={true}
                    // onSubmit={handleSubmit}
                    // validationSchema={storePreferencesSchema}
                    enableReinitialize={true}
                    validateOnMount={true}
                  >
                    {({
                      isValid,
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      setTouched,
                      setFieldValue
                    }) => (
                      <form style={{ height: '100%' }}>
                        <div className="row">
                          <div className="col-12">
                            <h4 className="text-center">Sale Summary</h4>
                            <div className="row justify-content-center border rounded py-1 mt-1">
                              <div className="col-12">
                                <div className="row mt-1 justify-content-center">
                                  <label className="col-7 col-form-label col-form-label-sm text-start my-auto fw-bold text-teal">Total Sell Price:</label>
                                  <label className="col-5 pe-1 col-form-label col-form-label-sm ms-0 my-auto text-end fw-bold text-teal"><i className="fe fe-dollar-sign" ></i> {(values.totalSalePrice ? Number(parseFloat(values.totalSalePrice)).toFixed(2) : 0) || 0}</label>
                                </div>
                                <div className="row mt-1 justify-content-center">
                                  <label className="col-7 col-form-label col-form-label-sm text-start my-auto ps-4">Sell Price:</label>
                                  <div className="col-5 ps-0 my-auto">
                                    <label className="my-auto text-end fs-12">
                                      <i className="fe fe-dollar-sign" ></i>
                                      {(values.salePrice ? Number(parseFloat(values.salePrice)).toFixed(2) : 0) || 0}
                                    </label>

                                    {/* <DrInput className="form-control form-control-sm" placeholder="" name="salePrice" type="number" min="0" step="0.01"
                                  value={values.salePrice || "0"}
                                  // onChange={handleChange}
                                  // onBlur={handleBlur}(e.currentTarget.value?.length > 0 ? parseFloat(e.currentTarget.value) : 0)
                                  onChange={(e) => {
                                    e.preventDefault();
                                    setFieldValue('salePrice', e.currentTarget.value);
                                    setFieldValue('totalSalePrice', ((e.currentTarget.value?.length > 0 ? parseFloat(e.currentTarget.value) : 0) + parseFloat(values.saleShippingPrice) + parseFloat(values.saleTax)))

                                    setFieldValue('totalCost', (parseFloat(values.totalProductCost + values.totalWarehouseCost)));
                                  }}
                                  onBlur={() => { setTouched({ ...touched, 'salePrice': true, 'saleTax': true, 'saleShippingPrice': true, 'totalSalePrice': true }) }}
                                  // disabled={"disabled"}
                                  iconPosition={"left"}
                                  icon={<i className="fe fe-dollar-sign" ></i>}
                                /> */}
                                  </div>
                                </div>
                                <div className="row mt-1 justify-content-center">
                                  <label className="col-7 col-form-label col-form-label-sm text-start my-auto ps-4">Shipping Price:</label>
                                  <div className="col-5 ps-0 my-auto">
                                    <label className="my-auto text-end fs-12">
                                      <i className="fe fe-dollar-sign" ></i>
                                      {(values.saleShippingPrice ? Number(parseFloat(values.saleShippingPrice)).toFixed(2) : 0) || 0}
                                    </label>
                                    {/* <DrInput className="form-control form-control-sm" placeholder="" name="saleShippingPrice" type="number" min="0" step="0.01"
                                  value={values.saleShippingPrice || "0"}

                                  onChange={(e) => {
                                    e.preventDefault();
                                    setFieldValue('saleShippingPrice', e.currentTarget.value);
                                    setFieldValue('totalSalePrice', ((e.currentTarget.value?.length > 0 ? parseFloat(e.currentTarget.value) : 0) + parseFloat(values.salePrice) + parseFloat(values.saleTax)))

                                    setFieldValue('totalCost', (parseFloat(values.totalProductCost + values.totalWarehouseCost)));
                                  }}
                                  onBlur={() => { setTouched({ ...touched, 'salePrice': true, 'saleTax': true, 'saleShippingPrice': true, 'totalSalePrice': true }) }}

                                  // disabled={"disabled"}
                                  iconPosition={"left"}
                                  icon={<i className="fe fe-dollar-sign" ></i>}
                                /> */}
                                  </div>
                                </div>
                                <div className="row mt-1 justify-content-center">
                                  <label className="col-7 col-form-label col-form-label-sm text-start my-auto ps-4">Tax:</label>
                                  <div className="col-5 ps-0 my-auto">
                                    <label className="my-auto text-end fs-12">
                                      <i className="fe fe-dollar-sign" ></i>
                                      {(values.saleTax ? Number(parseFloat(values.saleTax)).toFixed(2) : 0) || 0}
                                    </label>
                                    {/* <DrInput className="form-control form-control-sm" placeholder="" name="saleTax" type="number" min="0" step="0.01"
                                  value={values.saleTax || 0}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    setFieldValue('saleTax', e.currentTarget.value);
                                    setFieldValue('totalSalePrice', ((e.currentTarget.value?.length > 0 ? parseFloat(e.currentTarget.value) : 0) + parseFloat(values.salePrice) + parseFloat(values.saleShippingPrice)))

                                    setFieldValue('totalCost', (parseFloat(values.totalProductCost + values.totalWarehouseCost)));
                                  }}
                                  onBlur={() => { setTouched({ ...touched, 'salePrice': true, 'saleTax': true, 'saleShippingPrice': true, 'totalSalePrice': true }) }}
                                  // disabled={"disabled"}
                                  iconPosition={"left"}
                                  icon={<i className="fe fe-dollar-sign" ></i>}
                                /> */}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row justify-content-center border rounded py-1 mt-1">
                              <div className="col-12">
                                <div className="row mt-1 justify-content-center">
                                  <label className="col-7 col-form-label col-form-label-sm text-start my-auto fw-bold text-teal">Amazon Fee:</label>
                                  <label className="col-5 pe-1 col-form-label col-form-label-sm ms-0 my-auto text-end fw-bold text-teal">
                                    <i className="fe fe-dollar-sign" ></i>
                                    {(values.amazonFee ? Number(parseFloat(values.amazonFee)).toFixed(2) : 0) || 0}
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="row justify-content-center border rounded py-1 mt-1">
                              <div className="col-12">
                                <div className="row mt-1 justify-content-center">
                                  <label className="col-7 col-form-label col-form-label-sm text-start my-auto fw-bold text-teal">Total Cost:</label>
                                  <label className="col-5 pe-1 col-form-label col-form-label-sm ms-0 my-auto text-end fw-bold text-teal"><i className="fe fe-dollar-sign" ></i>{(values.totalCost ? Number(parseFloat(values.totalCost)).toFixed(2) : 0) || 0}</label>
                                </div>

                                <div className="row mt-1 justify-content-center">
                                  <label className="col-7 ps-4 col-form-label col-form-label-sm text-start my-auto fw-bold text-teal">Warehouse Cost:</label>
                                  <label className="col-5 pe-1 col-form-label col-form-label-sm ms-0 my-auto text-end fw-bold text-teal"><i className="fe fe-dollar-sign" ></i>{(values.totalWarehouseCost ? Number(parseFloat(values.totalWarehouseCost)).toFixed(2) : 0) || 0}</label>
                                </div>
                                <div className="row mt-1 justify-content-center">
                                  <label className="col-6 col-form-label col-form-label-sm text-start my-auto ps-4">Ship From Warehouse:</label>
                                  <div className="col-5 ps-0">
                                    <DrInput className="form-control form-control-sm" placeholder="" name="shipFromWarehousePrice" type="number" min="0" step="0.01"
                                      value={values.shipFromWarehousePrice || 0}
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setFieldValue('shipFromWarehousePrice', e.currentTarget.value);
                                        setFieldValue('totalWarehouseCost', ((e.currentTarget.value?.length > 0 ? parseFloat(e.currentTarget.value) : 0) + parseFloat(values.returnToWarehousePrice) + parseFloat(values.extraWarehouseCost)))

                                        setFieldValue('totalCost', (parseFloat(values.totalProductCost + values.totalWarehouseCost)));
                                      }}
                                      onBlur={() => { setTouched({ ...touched, 'shipFromWarehousePrice': true, 'returnToWarehousePrice': true, 'extraWarehouseCost': true, 'totalWarehouseCost': true }) }}
                                      // disabled={"disabled"}
                                      iconPosition={"left"}
                                      icon={<i className="fe fe-dollar-sign" ></i>}
                                    />
                                  </div>
                                </div>
                                <div className="row mt-1 justify-content-center">
                                  <label className="col-6 col-form-label col-form-label-sm text-start my-auto ps-4">Return To Warehouse:</label>
                                  <div className="col-5 ps-0">
                                    <DrInput className="form-control form-control-sm" placeholder="" name="returnToWarehousePrice" type="number" min="0" step="0.01"
                                      value={values.returnToWarehousePrice || 0}
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setFieldValue('returnToWarehousePrice', e.currentTarget.value);
                                        setFieldValue('totalWarehouseCost', ((e.currentTarget.value?.length > 0 ? parseFloat(e.currentTarget.value) : 0) + parseFloat(values.shipFromWarehousePrice) + parseFloat(values.extraWarehouseCost)))

                                        setFieldValue('totalCost', (parseFloat(values.totalProductCost + values.totalWarehouseCost)));
                                      }}
                                      onBlur={() => { setTouched({ ...touched, 'shipFromWarehousePrice': true, 'returnToWarehousePrice': true, 'extraWarehouseCost': true, 'totalWarehouseCost': true }) }}
                                      // disabled={"disabled"}
                                      iconPosition={"left"}
                                      icon={<i className="fe fe-dollar-sign" ></i>}
                                    />
                                  </div>
                                </div>
                                <div className="row mt-1 justify-content-center">
                                  <label className="col-6 col-form-label col-form-label-sm text-start my-auto ps-4">Extra Warehouse Cost:</label>
                                  <div className="col-5 ps-0">
                                    <DrInput className="form-control form-control-sm" placeholder="" name="extraWarehouseCost" type="number" min="0" step="0.01"
                                      value={values.extraWarehouseCost || 0}
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setFieldValue('extraWarehouseCost', e.currentTarget.value);
                                        setFieldValue('totalWarehouseCost', ((e.currentTarget.value?.length > 0 ? parseFloat(e.currentTarget.value) : 0) + parseFloat(values.returnToWarehousePrice) + parseFloat(values.shipFromWarehousePrice)))

                                        setFieldValue('totalCost', (parseFloat(values.totalProductCost + values.totalWarehouseCost)));
                                      }}
                                      onBlur={() => { setTouched({ ...touched, 'shipFromWarehousePrice': true, 'returnToWarehousePrice': true, 'extraWarehouseCost': true, 'totalWarehouseCost': true }) }}
                                      // disabled={"disabled"}
                                      iconPosition={"left"}
                                      icon={<i className="fe fe-dollar-sign" ></i>}
                                    />
                                  </div>
                                </div>

                                <div className="row mt-1 justify-content-center">
                                  <label className="col-7 ps-4 col-form-label col-form-label-sm text-start my-auto fw-bold text-teal">Product Cost:</label>
                                  <label className="col-5 pe-1 col-form-label col-form-label-sm ms-0 my-auto text-end fw-bold text-teal"><i className="fe fe-dollar-sign" ></i>{(values.totalProductCost ? Number(parseFloat(values.totalProductCost)).toFixed(2) : 0) || 0}</label>
                                </div>
                                <div className="row mt-1 justify-content-center">
                                  <label className="col-6 col-form-label col-form-label-sm text-start my-auto ps-4">Product Price:</label>
                                  <div className="col-5 ps-0">
                                    <DrInput className="form-control form-control-sm" placeholder="" name="productPrice" type="number" min="0" step="0.01"
                                      value={values.productPrice || 0}
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setFieldValue('productPrice', e.currentTarget.value);
                                        setFieldValue('totalProductCost', ((e.currentTarget.value?.length > 0 ? parseFloat(e.currentTarget.value) : 0) + parseFloat(values.productShippingPrice)))

                                        setFieldValue('totalCost', (parseFloat(values.totalProductCost + values.totalWarehouseCost)));
                                      }}
                                      onBlur={() => { setTouched({ ...touched, 'productPrice': true, 'productShippingPrice': true, 'totalProductCost': true }) }}
                                      // disabled={"disabled"}
                                      iconPosition={"left"}
                                      icon={<i className="fe fe-dollar-sign" ></i>}
                                    />
                                  </div>
                                </div>
                                <div className="row mt-1 justify-content-center">
                                  <label className="col-6 col-form-label col-form-label-sm text-start my-auto ps-4">Product Shipping Price:</label>
                                  <div className="col-5 ps-0">
                                    <DrInput className="form-control form-control-sm" placeholder="" name="productShippingPrice" type="number" min="0" step="0.01"
                                      value={values.productShippingPrice || 0}
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setFieldValue('productShippingPrice', e.currentTarget.value);
                                        setFieldValue('totalProductCost', ((e.currentTarget.value?.length > 0 ? parseFloat(e.currentTarget.value) : 0) + parseFloat(values.productPrice)));

                                        setFieldValue('totalCost', (parseFloat(values.totalProductCost + values.totalWarehouseCost)));
                                      }}
                                      onBlur={() => { setTouched({ ...touched, 'productPrice': true, 'productShippingPrice': true, 'totalProductCost': true }) }}
                                      // disabled={"disabled"}
                                      iconPosition={"left"}
                                      icon={<i className="fe fe-dollar-sign" ></i>}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row justify-content-center border rounded py-1 mt-1">
                              <div className="col-12">
                                <div className="row mt-1 justify-content-center">
                                  <label className="col-7 col-form-label col-form-label-sm text-start my-auto fw-bold text-danger">Fee Refund:</label>
                                  <div className="col-5 ps-0">
                                    <DrInput className="form-control form-control-sm text-danger" placeholder="" name="feeRefundValue" type="number" min="0" step="0.01"
                                      value={feeRefundValue || 0}
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setFeeRefundValue((e.currentTarget.value?.length > 0 ? parseFloat(e.currentTarget.value) : 0));
                                      }}
                                      onBlur={handleBlur}
                                      iconPosition={"left"}
                                      icon={<i className="fe fe-dollar-sign text-danger" ></i>}
                                    />
                                  </div>
                                </div>
                                <div className="row mt-1 justify-content-center">
                                  <label className="col-7 col-form-label col-form-label-sm text-start my-auto fw-bold text-danger">Seller Refund:</label>
                                  <div className="col-5 ps-0">
                                    <DrInput className="form-control form-control-sm text-danger" placeholder="" name="sellerRefundValue" type="number" min="0" step="0.01"
                                      value={sellerRefundValue || 0}
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setSellerRefundValue((e.currentTarget.value?.length > 0 ? parseFloat(e.currentTarget.value) : 0));
                                      }}
                                      onBlur={handleBlur}
                                      iconPosition={"left"}
                                      icon={<i className="fe fe-dollar-sign text-danger" ></i>}
                                    />
                                  </div>
                                </div>
                                <div className="row mt-1 justify-content-center">
                                  <label className="col-7 col-form-label col-form-label-sm text-start my-auto fw-bold text-success">Buyer Refund:</label>
                                  <div className="col-5 ps-0">
                                    <DrInput className="form-control form-control-sm text-success" placeholder="" name="buyerRefundValue" type="number" min="0" step="0.01"
                                      value={buyerRefundValue || 0}
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setBuyerRefundValue((e.currentTarget.value?.length > 0 ? parseFloat(e.currentTarget.value) : 0));
                                      }}
                                      onBlur={handleBlur}
                                      // disabled={"disabled"}
                                      iconPosition={"left"}
                                      icon={<i className="fe fe-dollar-sign text-success" ></i>}
                                    />
                                  </div>
                                </div>
                                <div className="row mt-1 justify-content-center">
                                  <label className="col-7 col-form-label col-form-label-sm text-start my-auto fw-bold text-success">Returned Customs Duty:</label>
                                  <div className="col-5 ps-0">
                                    <DrInput className="form-control form-control-sm text-success" placeholder="" name="returnedCustomsDutyPriceValue" type="number" min="0" step="0.01"
                                      value={returnedCustomsDutyPriceValue || 0}
                                      onChange={(e) => {
                                        e.preventDefault();
                                        setReturnedCustomsDutyPriceValue((e.currentTarget.value?.length > 0 ? parseFloat(e.currentTarget.value) : 0));
                                      }}
                                      onBlur={handleBlur}
                                      // disabled={"disabled"}
                                      iconPosition={"left"}
                                      icon={<i className="fe fe-dollar-sign text-success" ></i>}
                                    />
                                  </div>
                                </div>
                                <div className="row mt-1 justify-content-center">
                                  <label className="col-7 col-form-label col-form-label-sm text-start my-auto fw-bold">Product Tax:</label>
                                  <div className="col-5 ps-0">
                                    <DrInput className="form-control form-control-sm" placeholder="" name="productTax" type="number" min="0" step="0.01"
                                      value={values.productTax || 0}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      // disabled={"disabled"}
                                      iconPosition={"left"}
                                      icon={<i className="fe fe-dollar-sign" ></i>}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-1 justify-content-center border rounded py-1">
                              <label className="col-7 col-form-label col-form-label-sm text-start my-auto fw-bold text-teal">Credit To Account Price:</label>
                              <label className="col-5 pe-1 col-form-label col-form-label-sm ms-0 my-auto text-end fw-bold text-teal"><i className="fe fe-dollar-sign" ></i> {values.creditToAccountPrice || 0}</label>
                            </div>
                            <div className="row justify-content-center border rounded py-1 mt-1">
                              <div className="col-12">
                                <div className="row mt-1 justify-content-center">
                                  <label className="col-7 col-form-label col-form-label-sm text-start my-auto fw-bold text-success">Profit Amount:</label>
                                  <div className="col-5 ps-0">
                                    <DrInput className={`form-control form-control-sm  ${(values.profitAmount || 0) >= 0 ? "text-success" : "text-danger"}`} placeholder="" name="profitAmount" type="number" step="0.01"
                                      value={values.profitAmount || 0}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      // disabled={"disabled"}
                                      iconPosition={"left"}
                                      icon={<i className={`fe fe-dollar-sign ${(values.profitAmount || 0) >= 0 ? "text-success" : "text-danger"}`} ></i>}
                                    />
                                  </div>
                                </div>

                                <div className="row mt-1 justify-content-center">
                                  <label className="col-7 col-form-label col-form-label-sm text-start my-auto fw-bold text-success">Profit Percent:</label>
                                  <div className="col-5 ps-0">
                                    <DrInput className={`form-control form-control-sm  ${(values.profitPercent || 0) >= 0 ? "text-success" : "text-danger"}`} placeholder="" name="profitPercent" type="number" step="0.01"
                                      value={values.profitPercent || 0}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      // disabled={"disabled"}
                                      iconPosition={"right"}
                                      icon={<i className={`fe fe-percent ${(values.profitPercent || 0) >= 0 ? "text-success" : "text-danger"}`} ></i>}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row justify-content-center mt-3 mb-4">
                          <div className="col-12 ">
                            <button className={`btn btn-primary btn-wave waves-effect waves-light shadow btn-block ${isValid ? "" : "disabled"}`}
                              onClick={(e) => {
                                e.preventDefault();
                                if (isValid) {
                                  console.log(values)

                                  let request = {
                                    orderId: orderId,
                                    requestData: {

                                      orderTotalAmount: values.totalSalePrice,
                                      orderTotalCurrencyCode: "USD",
                                      orderItemAmount: values.productPrice,
                                      orderItemCurrencyCode: "USD",
                                      fastShippingPrice: values.saleShippingPrice,
                                      totalTax: values.saleTax,
                                      amazonFee: values.amazonFee,
                                      shippingCost: values.productShippingPrice,
                                      // customsDutyPrice: "",
                                      progressPayment: values.totalCost,
                                      profitPercent: values.profitPercent,
                                      profitPrice: values.profitAmount,
                                      warehousePrice: values.shipFromWarehousePrice,
                                      sellingPrice: values.salePrice,

                                      RefundsTotal: 0 - (sellerRefundValue + feeRefundValue) + (buyerRefundValue + returnedCustomsDutyPriceValue),
                                      RefundsTotalCurrencyCode: "USD"
                                    }
                                  }
                                  dispatch(withActionPromise(updateOrderDetailSaleSummary(request)));

                                }
                              }}
                            >Save Changes</button>
                          </div>
                        </div>

                      </form>
                    )
                    }
                  </Formik>
                </div>
              </div>
            </div>

            {orderDetailData?.orderDetailList?.length >= 3 &&
              <div className="row row-md row-lg justify-content-sm-center justify-content-md-center justify-content-center ps-3 flex-grow-shrink">
                <div className="card custom-card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <h4 className="mb-3 text-center">Shipment Status</h4>

                        <DrTimeLine variant={timelineVariants.center}
                          timelineSource={(orderTrackingStatuses?.length > 0
                            && orderTrackingStatuses.map(x => ({
                              groupName: x.groupName,
                              title: x.buyerSellerOrderStatus,
                              text: x.CarrierName || x.trackingNumber,
                              time: x.updatedDate

                            }))) || []}
                          className="timeline-sm"
                        ></DrTimeLine>
                        {!orderTrackingStatuses?.length > 0 &&

                          <h6 className="mb-3 text-center">NO TRACKING INFO</h6>
                        }
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            }
          </div>
        </Restricted>
      </div>
      {showPDF && selectedStore?.customerMainSellerId &&
        <DrPDFViewer
          selectedStore={selectedStore.customerMainSellerId}
          orderId={orderId}
          pdfData={invoice}
        />
      }


    </>
  )
}
export default OrderDetailPage