import React, { useEffect, useState } from 'react'
import { DrBreadCrump, DrColumnChart, DrDualDatePicker, DrLineChart, DrPieChart } from '../../components/component-index';
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import { getURLParams } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getLatestOrders } from '../../state-management/actions/orders.actions';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { clearActiveBuyoutCarrierSelectionModal, setActiveBuyoutCarrierSelectionModal, setActivePictureModal, withActionPromise } from '../../state-management/actions/app.actions';
import { modals } from '../../utils/constants';
import { getBestSellingProducts } from '../../state-management/actions/reports.actions';
import { useNavigate } from 'react-router-dom';
import { months, timeOptions } from '../../components/hoc/Constants/dr-constants.component';
import Dragula from 'react-dragula';
import productSales from '../../assets/img/product-sales.png'
import forecastSnow from '../../assets/img/forecast-snow.png'
import transferredToStore from '../../assets/img/transferred-to-store.png'
import { getOrderStatusCount, getStoreDashboardData } from '../../state-management/actions/store.actions';
import { setHomePageSelections } from '../../state-management/actions/settings.actions';
import { getInventorySummary } from '../../state-management/actions/inventory.actions';
import Restricted from '../../security/Restricted';

Chart.register(CategoryScale);


function HomePage() {
  const { REACT_APP_ROLES_SUPERADMIN, REACT_APP_ROLES_ADMIN, REACT_APP_ROLES_ORDERS } = process.env;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('homePage');
  const selectedStore = useSelector(state => state.stores.selectedStore);
  const latestOrderList = useSelector(state => state.orders.latestOrderList);
  const bestSellingProducts = useSelector(state => state.reports.bestSellingProducts);
  const inventorySummary = useSelector(state => state.inventory.inventorySummary);
  const inventoryProductsCategories = useSelector(state => state.reports.inventoryProductsCategories);
  const storeOrderStatusCounts = useSelector(state => state.stores.storeOrderStatusCounts);
  const storeDashBoardData = useSelector(state => state.stores.storeDashBoardData);
  const pageSelections = useSelector(state => state.settings?.pageSelections?.homePageSelections);
  const selectedStoreCurrencyType = useSelector(state => state.settings?.selectedStoreCurrencyType);

  const [draggedColumns, setDraggedColumns] = useState([]);

  const amazonCode = getURLParams('spapi_oauth_code');
  const amazonState = getURLParams('state');
  const redirectPath = useSelector(state => state.createStoreSnapshot?.connectToAmazonCriteria?.redirectPath);

  const [bestSellingProductsTableData, setBestSellingProductsTableData] = useState([]);
  const [inventoryProductsCategoriesTableData, setInventoryProductsCategoriesTableData] = useState([]);

  useEffect(() => {

   // let options = {};
    // Dragula(
    //   [
    //     document.querySelector('#draggable-left'),
    //     document.querySelector('#draggable-right'),
    //   ],
    //   options
    // );
    var initialColumns = [document.querySelector('#draggable-left'), document.querySelector('#draggable-right'), document.querySelector('#draggable-bottom')]
    // setDraggedColumns(initialColumns)

    let options = {};

    const dragula = Dragula(initialColumns, options);
    // dragula.on('drop', (el, target, source, sibling) => {
    //   const newColumnIndex = target.id === "draggable-left" ? 0 : 1;
    //   const previousColumnIndex = target.id === "draggable-left" ? 0 : 1;


    //   const belowId = sibling?.id;
    //   const itemId = el?.id;

    //   let columns = (draggedColumns?.length > 0 && draggedColumns) || initialColumns;
    //   if (belowId === undefined) {

    //     const newItemIndex = columns[newColumnIndex].children.length;
    //     const itemIndex = Array.prototype.indexOf.call(columns[previousColumnIndex].children, document.getElementById(itemId));

    //     // columns[previousColumnIndex].children.splice(columns[previousColumnIndex].children.indexOf(itemId), 1);
    //     // columns[previousColumnIndex].innerHTML = '';
    //     columns[previousColumnIndex].replaceChildren(...Array.from(columns[previousColumnIndex].children).splice(itemIndex, 1));
    //     // columns[previousColumnIndex].children =  Array.from(columns[previousColumnIndex].children).splice(itemIndex, 1);


    //     // columns[newColumnIndex].children.splice(newItemIndex, 0, itemId);
    //     // columns[newColumnIndex].innerHTML = '';
    //     columns[newColumnIndex].replaceChildren(...Array.from(columns[newColumnIndex].children).splice(newItemIndex, 0, itemId));
    //     // columns[newColumnIndex].children = Array.from(columns[newColumnIndex].children).splice(newItemIndex, 0, itemId);

    //     setDraggedColumns({ columns });
    //   }
    //   else {
    //     const newItemIndex = Array.prototype.indexOf.call(columns[newColumnIndex].children, document.getElementById(belowId));
    //     const itemIndex = Array.prototype.indexOf.call(columns[previousColumnIndex].children, document.getElementById(itemId));
    //     // const newItemIndex = columns[newColumnIndex].children.indexOf(belowId);
    //     // const newItemIndex = columns[newColumnIndex].children.getElementById(belowId).index();

    //     // columns[previousColumnIndex].children.splice(itemIndex, 1);
    //     // columns[newColumnIndex].children.splice(newItemIndex, 0, itemId);
    //     columns[previousColumnIndex].children = Array.from(columns[previousColumnIndex].children).splice(itemIndex, 1);
    //     columns[newColumnIndex].children = Array.from(columns[newColumnIndex].children).splice(newItemIndex, 0, itemId);
    //     setDraggedColumns({ columns });
    //   }

    //   if (dragula.onDrag !== undefined) {
    //     dragula.onDrag(columns);
    //   }
    // })

    if (amazonCode && amazonState) {
      let url = window.location.origin + (redirectPath?.length > 0 ? ("/#" + redirectPath) : "/#/mystore") + "?spapi_oauth_code=" + amazonCode + "&state=" + amazonState;

      window.location = url;
    }
    else {
      // dispatch(withActionPromise(getInventorySummary()));
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedStore?.customerMainSellerId) {
      dispatch(withActionPromise(getBestSellingProducts({ customerMainSellerId: selectedStore?.customerMainSellerId, take: 10 })));
      dispatch(withActionPromise(getInventorySummary({ customerMainSellerId: selectedStore?.customerMainSellerId })));
      dispatch(getLatestOrders({
        customerMainSellerId: selectedStore?.customerMainSellerId,
        orderStatus: null,
        // startOrderDate: compareDateStart,
        take: 10
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStore?.customerMainSellerId]);

  useEffect(() => {

    if (selectedStore?.customerMainSellerId) {
      if (pageSelections?.dateInterval && pageSelections?.dateInterval.length > 0 && (pageSelections.dateInterval[0] || pageSelections.dateInterval[1])) {
        var localDate = new Date();
        var compareDateStart = (pageSelections.dateInterval[0] && moment(pageSelections.dateInterval[0]).startOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).subtract(1, 'years').startOf('day').format("YYYY-MM-DD");
        var compareDateEnd = (pageSelections.dateInterval[1] && moment(pageSelections.dateInterval[1]).endOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).endOf('day').format("YYYY-MM-DD");

        dispatch(getStoreDashboardData(
          {
            customerMainSellerId: selectedStore?.customerMainSellerId,
            startDate: compareDateStart,
            endDate: compareDateEnd,
            graphicType: pageSelections?.graphicType
          }));

        dispatch(getOrderStatusCount({
          customerMainSellerId: selectedStore?.customerMainSellerId,
          startDate: compareDateStart,
          endDate: compareDateEnd
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSelections?.dateInterval, pageSelections?.graphicType, selectedStore?.customerMainSellerId]);

  useEffect(() => {
    if (bestSellingProducts?.length > 0) {
      setBestSellingProductsTableData(bestSellingProducts.sort((b, a) => a.numberOfSales - b.numberOfSales).slice(0, 15));
    }
    else {
      setBestSellingProductsTableData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bestSellingProducts]);

  useEffect(() => {
    if (inventoryProductsCategories?.length > 0) {
      // setInventoryProductsCategoriesTableData(inventoryProductsCategories.sort((a, b) => a.categoryName.localeCompare(b.categoryName)).slice(0, 10));
      setInventoryProductsCategoriesTableData(inventoryProductsCategories.sort((a, b) => b.categoryProductCount - a.categoryProductCount).slice(0, 8));
    }
    else {
      setInventoryProductsCategoriesTableData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryProductsCategories]);

  return (
    <>
      <DrBreadCrump > </DrBreadCrump>
      <div className="row justify-content-end mb-2">
        <div className="col-auto">
          <DrDualDatePicker
            className="form-control-sm"
            pulse={true}
            selectedDates={pageSelections?.dateInterval}
            onCloseCallBack={(dateValues) => {
              var dates = [(dateValues[0] ? moment.utc(dateValues[0].toString(), "YYYY/MM/DD").startOf('day').format("YYYY/MM/DD") : null), (dateValues[1] ? moment.utc(dateValues[1].toString(), "YYYY/MM/DD").startOf('day').format() : null)];
              dispatch(withActionPromise(setHomePageSelections({ dateInterval: dates, graphicType: pageSelections?.graphicType })));
            }}
            timeOptionsSource={timeOptions}
          ></DrDualDatePicker>
        </div>
      </div>
      <div className="row row-sm">
        <div className="col-xl-4 d-flex">
          <div className="card custom-card transform-top-hover bg-success bg-opacity-10">
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <div className="p-2" style={{ height: '80px' }}>
                    <img src={productSales} alt=""></img>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row justify-content-around">
                    <div className="col-auto">
                      <h5 className="fw-semibold">Ürün Satışları</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-auto">
                      <h3 className="fw-bold mb-0 mt-2">{selectedStoreCurrencyType.currencyCode} {Number(storeDashBoardData?.productSales || 0).toFixed(2)}</h3>
                    </div>
                  </div>
                </div>

              </div>
              <div className="row justify-content-end">
                <div className="col-auto d-flex ">
                  <i className="fe fe-arrow-up text-success fw-bold fs-24"></i>
                  <h5 className="text-success m-auto">%0</h5>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="col-xl-4 d-flex">
          <div className="card custom-card transform-top-hover bg-secondary bg-opacity-10">
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <div className="p-2" style={{ height: '80px' }}>
                    <img src={forecastSnow} alt=""></img>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row justify-content-around">
                    <div className="col-auto">
                      <h5 className="fw-semibold">Tahmini Kar</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-auto">
                      <h3 className="fw-bold mb-0 mt-2">{selectedStoreCurrencyType.currencyCode} {Number(storeDashBoardData?.estimatedProfit || 0).toFixed(2)}</h3>
                    </div>
                  </div>
                </div>

              </div>
              <div className="row justify-content-end">
                <div className="col-auto d-flex ">
                  <i className="fe fe-arrow-up text-secondary fw-bold fs-24"></i>
                  <h5 className="text-secondary m-auto">%0</h5>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="col-xl-4 d-flex">
          <div className="card custom-card transform-top-hover bg-warning bg-opacity-10">
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <div className="p-2" style={{ height: '80px' }}>
                    <img src={transferredToStore} alt=""></img>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row justify-content-around">
                    <div className="col-auto">
                      <h5 className="fw-semibold">Mağazaya Aktarılan</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-auto">
                      <h3 className="fw-bold mb-0 mt-2">{storeDashBoardData?.transferredtoStore || 0}</h3>
                    </div>
                  </div>
                </div>

              </div>
              {/* <div className="row my-2 justify-content-end">
                <div className="col-auto d-flex ">
                  <i className="fe fe-arrow-up text-success fw-bold fs-24"></i>
                  <h5 className="text-success m-auto">%18.3</h5>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div id="draggable-left" className="col-xl-8 pb-4 d-flex flex-column">
          <div id="chart" className="card custom-card chart">
            <div className="card-body">
              <div className="row justify-content-start mb-2">
                <div className="col-auto">
                  <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" className="btn-check" name="btnradio" id="btnradio1" value={0} checked={pageSelections?.graphicType === 0}
                      onChange={(e) => {

                        dispatch(withActionPromise(setHomePageSelections({ dateInterval: pageSelections?.dateInterval, graphicType: 0 })));

                      }} ></input>
                    <label className="btn btn-outline-teal" htmlFor="btnradio1" >Sipariş</label>
                    <input type="radio" className="btn-check" name="btnradio" id="btnradio2" value={1} checked={pageSelections?.graphicType === 1}
                      onChange={(e) => {

                        dispatch(withActionPromise(setHomePageSelections({ dateInterval: pageSelections?.dateInterval, graphicType: 1 })));

                      }} >

                    </input>
                    <label className="btn btn-outline-teal" htmlFor="btnradio2">Kâr</label>
                    <input type="radio" className="btn-check" name="btnradio" id="btnradio3" value={2} checked={pageSelections?.graphicType === 2}
                      onChange={(e) => {

                        dispatch(withActionPromise(setHomePageSelections({ dateInterval: pageSelections?.dateInterval, graphicType: 2 })));

                      }} >

                    </input>
                    <label className="btn btn-outline-teal" htmlFor="btnradio3">Maliyet</label>
                  </div>
                </div>
              </div>
              <div className="row" >
                <div className="col">
                  {/* <DrLineChart chartData={chartData} options={chartOptions} /> */}
                  <DrColumnChart
                    chartData={{
                      // series: storeDashBoardData?.orderGraphichs?.axis?.sort((b, a) => moment(b.xData, "yyyy-MM-dd HH:mm:ss").valueOf() - moment(a.xData, "yyyy-MM-dd HH:mm:ss").valueOf()).map(x => Number(x.yData).toFixed(1)) || [],
                      // categories: storeDashBoardData?.orderGraphichs?.axis?.sort((b, a) => moment(b.xData, "yyyy-MM-dd HH:mm:ss").valueOf() - moment(a.xData, "yyyy-MM-dd HH:mm:ss").valueOf()).map(x => { return { xData: x.xData, xDataType: x.xDataType } }) || [],

                      data: storeDashBoardData?.orderGraphichs?.axis?.sort((b, a) => moment(b.xData, "YYYY-MM-DDThh:mm:ss").valueOf() - moment(a.xData, "YYYY-MM-DDThh:mm:ss").valueOf()),
                      // categories: storeDashBoardData?.orderGraphichs?.axis?.sort((b, a) => moment(a.xData, "YYYY-MM-DDThh:mm:ss").valueOf() - moment(b.xData, "YYYY-MM-DDThh:mm:ss").valueOf()).map((x, index) => {
                      //   return (

                      //     index % (storeDashBoardData?.orderGraphichs?.axis.length <= 31 ? 2 : (storeDashBoardData?.orderGraphichs?.axis.length > 31 && storeDashBoardData?.orderGraphichs?.axis.length <= 90 ? 5 : 13)) === 0 ? (moment(x.xData).format('L') + "|true") : (moment(x.xData).format('L') + "|false")

                      //   )
                      // }) || [],
                      colors: ['var(--info-color)', 'var(--danger-color)', 'var(--success-color)'],
                      peakText: pageSelections?.graphicType === 0 ? "Order Count" : pageSelections?.graphicType === 1 ? "Profit" : "Cost",
                      // title: "Inventory Status"
                    }}
                    showLessOnxAxis={true}
                  ></DrColumnChart>
                </div>

              </div>
            </div>
          </div>
          <div id="most-sold-products" className="card custom-card flex-grow-shrink">
            <div className="card-body flex-unset">

              <div className="row justify-content-between">
                <div className="col-auto">
                  <h6 className="card-title mb-1">{t('mostSoldProductsWidget.title')}</h6>
                </div>
                <div className="col-auto">
                  <button className="btn btn-link btn-sm btn-wave waves-effect waves-light"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/best-selling")
                    }}>
                    Go To Best Selling Products
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body flex-unset">
              <div className="table-responsive">
                <table className="table table-hover table-bordered border-primary">
                  <thead>
                    <tr>
                      <th></th>
                      <th >{t('mostSoldProductsWidget.table.productName')}</th>
                      <th className="text-center">{t('mostSoldProductsWidget.table.asin')}</th>
                      <th className="text-center">{t('mostSoldProductsWidget.table.category')}</th>
                      <th className="text-center">{t('mostSoldProductsWidget.table.orderCount')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bestSellingProductsTableData?.map((productItem, index) => {

                      return (
                        <tr key={"mostSoldProductItem_" + index}>
                          <td>
                            <div className="card-aside-img cursor-pointer" style={{ textAlign: 'start' }} >
                              <div className="img-sm p-0 m-0 rounded-0">
                                <img src={productItem.productPicture} alt="img" className="img-thumbnail" data-tag="pic-modal-opener"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (productItem.productPicture) {
                                      dispatch(setActivePictureModal({ name: modals.picture, isGlobal: true, title: "picture", imgsource: [productItem.productPicture] }))
                                    }
                                  }}
                                ></img>
                              </div>
                            </div>
                          </td>
                          <td >
                            {productItem.productName}
                          </td>
                          <td className="text-center">
                            <p className="mb-0">
                              <span className="text-primary ms-2 cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  // navigate('/product-detail/' + productItem.asin);
                                  window.open(window.location.origin + "/#/product-detail/" + productItem.asin, '_blank');
                                }} >
                                {productItem.asin}
                              </span>
                            </p>
                          </td>
                          <td className="text-center">
                            {productItem.categoryName}
                          </td>
                          <td className="text-center">
                            <span className="badge bg-info">
                              {productItem.numberOfSales}
                            </span>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div id="draggable-right" className="col-xl-4 pb-4 d-flex flex-column">
          <div id="completed-sales-report" className="card custom-card">
            <div className="card-body ">
              {/* <div className="row justify-content-between">
                <div className="col-auto"> */}
              <DrPieChart
                chartData={{
                  series: [
                    storeOrderStatusCounts?.length > 0 ? storeOrderStatusCounts.filter(x => x.status === 0 || x.status === 1 || x.status === 2 || x.status === 3).reduce((n, { orderCount }) => n + orderCount, 0) : 0,
                    storeOrderStatusCounts?.length > 0 ? storeOrderStatusCounts.filter(x => x.status === 5 || x.status === 6 || x.status === 7).reduce((n, { orderCount }) => n + orderCount, 0) : 0,
                    storeOrderStatusCounts?.length > 0 ? storeOrderStatusCounts.filter(x => x.status === 4).reduce((n, { orderCount }) => n + orderCount, 0) : 100,
                  ],
                  labels: ['Devam Eden Siparişler', 'Eksik Siparişler', 'Tamamlanan Siparişler'],
                  colors: ['var(--info-color)', 'var(--danger-color)', 'var(--success-color)'],
                }}
              ></DrPieChart>
              {/* </div>
              </div> */}
            </div>
          </div>
          <div id="inventory-state" className="card custom-card">
            <div className="card-body">



              <DrLineChart
                chartData={{
                  series: storeDashBoardData?.inventoryGraphics?.map(x => x.totalCount) || [],
                  categories: storeDashBoardData?.inventoryGraphics?.map(x => (months.find(y => y.value === x.month)?.labelShort || x.month.toString()) + " " + x.year.toString()) || [],
                  colors: ['var(--info-color)', 'var(--danger-color)', 'var(--success-color)'],
                  peakText: "Product Count",
                  title: "Inventory Status"
                }}
              >

              </DrLineChart>
            </div>
          </div>
          <div id="inventory-summary" className="card custom-card">
            <div className="card-body flex-unset">
              <div>
                <h6 className="card-title mb-1">{t('inventorySummaryWidget.title')}</h6>
              </div>
            </div>
            <div className="card-body flex-unset">
              <div className="row align-items-center justify-content-between border-bottom p-1">
                <div className="col-auto px-0">
                  <div className="avatar avatar-xs bg-teal text-white me-2"><i className="fs-12 fw-bold bi-star-fill text-white" /></div>
                </div>
                <div className="col">
                  {/* <p className="mb-0">{t('inventorySummaryWidget.table.totalProducts')}</p> */}
                  <button className="btn btn-link btn-wave waves-effect waves-light"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/inventory/f/transferredToStore");
                    }}
                  >
                    {t('inventorySummaryWidget.table.totalProducts')}
                  </button>
                </div>
                <div className="col-auto px-0 ms-auto">
                  <div className="d-flex">
                    <span className="font-weight-bold">{inventorySummary?.totalProductCount}</span>
                  </div>
                </div>
              </div>
              <div className="row align-items-center justify-content-between border-bottom p-1">
                <div className="col-auto px-0">
                  <div className="avatar avatar-xs bg-primary text-white me-2"><i className="fs-12 fw-bold bi-check2 text-white" /></div>
                </div>
                <div className="col">
                  {/* <p className="mb-0">{t('inventorySummaryWidget.table.buyboxProductCount')}</p> */}
                  <button className="btn btn-link btn-wave waves-effect waves-light"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/inventory/f/buybox");
                    }}
                  >
                    {t('inventorySummaryWidget.table.buyboxProductCount')}
                  </button>
                </div>
                <div className="col-auto px-0 ms-auto">
                  <div className="d-flex">
                    <span className="font-weight-bold">{inventorySummary?.buyboxProductCount}</span>
                  </div>
                </div>
              </div>
              <div className="row align-items-center justify-content-between  align-items-center border-bottom p-1">
                <div className="col-auto px-0">
                  <div className="avatar avatar-xs bg-secondary text-white me-2"><i className="fs-12 fw-bold bi-check2 text-white" /></div>
                </div>
                <div className="col">
                  {/* <p className="mb-0">{t('inventorySummaryWidget.table.lowestProductCount')}</p> */}
                  <button className="btn btn-link btn-wave waves-effect waves-light"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/inventory/f/lowest");
                    }}
                  >
                    {t('inventorySummaryWidget.table.lowestProductCount')}
                  </button>
                </div>
                <div className="col-auto px-0 ms-auto">
                  <div className="d-flex">
                    <span className="font-weight-bold">{inventorySummary?.lowestProductCount}</span>
                  </div>
                </div>
              </div>
              <div className="row align-items-center justify-content-between align-items-center border-bottom p-1">
                <div className="col-auto px-0">
                  <div className="avatar avatar-xs bg-warning text-white me-2"><i className="fs-12 fw-bold bi-lightning-charge-fill text-white" /></div>
                </div>
                <div className="col">
                  {/* <p className="mb-0">{t('inventorySummaryWidget.table.noStockProductCount')}</p> */}
                  <button className="btn btn-link btn-wave waves-effect waves-light"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/inventory/f/nostock");
                    }}
                  >
                    {t('inventorySummaryWidget.table.noStockProductCount')}
                  </button>
                </div>
                <div className="col-auto px-0 ms-auto">
                  <div className="d-flex">
                    <span className="font-weight-bold">{inventorySummary?.noStockProductCount}</span>
                  </div>
                </div>
              </div>
              <div className="row align-items-center justify-content-between align-items-center border-bottom p-1">
                <div className="col-auto px-0">
                  <div className="avatar avatar-xs bg-danger text-white me-2"><i className="fs-12 fw-bold bi-arrow-down text-white" /></div>
                </div>
                <div className="col">
                  {/* <p className="mb-0">{t('inventorySummaryWidget.table.minPriceProductCount')}</p> */}
                  <button className="btn btn-link btn-wave waves-effect waves-light"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/inventory/f/minprice");
                    }}
                  >
                    {t('inventorySummaryWidget.table.minPriceProductCount')}
                  </button>
                </div>
                <div className="col-auto px-0 ms-auto">
                  <div className="d-flex">
                    <span className="font-weight-bold">{inventorySummary?.minPriceProductCount}</span>
                  </div>
                </div>
              </div>
              <div className="row align-items-center justify-content-between align-items-center border-bottom p-1">
                <div className="col-auto px-0">
                  <div className="avatar avatar-xs bg-success text-white me-2"><i className="fs-12 fw-bold bi-arrow-up text-white" /></div>
                </div>
                <div className="col">
                  {/* <p className="mb-0">{t('inventorySummaryWidget.table.maxPriceProductCount')}</p> */}
                  <button className="btn btn-link btn-wave waves-effect waves-light"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/inventory/f/maxprice");
                    }}
                  >
                    {t('inventorySummaryWidget.table.maxPriceProductCount')}
                  </button>
                </div>
                <div className="col-auto px-0 ms-auto">
                  <div className="d-flex">
                    <span className="font-weight-bold">{inventorySummary?.maxPriceProductCount}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="category-list" className="card custom-card">
            <div className="card-body flex-unset">

              <div className="row justify-content-between">
                <div className="col-auto">
                  <h6 className="card-title mb-1">{t('categoriesWidget.title')}</h6>
                </div>
                <div className="col-auto">
                  <button className="btn btn-link btn-sm btn-wave waves-effect waves-light"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/categories");
                    }}>
                    Go to Categories
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body flex-unset">
              <div className="table-responsive">
                <table className="table table-hover table-bordered border-primary">
                  <tbody>
                    {inventoryProductsCategoriesTableData?.map((categoryItem, index) => {

                      return (
                        <tr key={"categoriesItem_" + index}>

                          <td>
                            <span className="badge bg-info bg-opacity-75 fs-13 cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/categories/" + categoryItem.categoryId)
                              }}>
                              {categoryItem.categoryName}
                            </span>
                          </td>
                          <td >
                            <span className="badge bg-info fs-13">
                              {categoryItem.categoryProductCount}
                            </span>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <div className="row justify-content-center">
                <div className="col-auto">
                  <button className="btn btn-link btn-sm btn-wave waves-effect waves-light"
                    onClick={(e) => {
                      e.preventDefault();
                      // setInventoryProductsCategoriesTableData(inventoryProductsCategories.sort((a, b) => a.categoryName.localeCompare(b.categoryName)));
                      setInventoryProductsCategoriesTableData(inventoryProductsCategories.sort((a, b) => b.categoryProductCount - a.categoryProductCount));
                    }}>
                    See All Categories
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div id="sale-summary" className="card custom-card">
            <div className="card-body">
              <div>
                <h6 className="card-title mb-1">Sales Summary</h6>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive ">
                <table className="table table-hover table-bordered border-primary">
                  <thead>
                    <tr>
                      <th>Period</th>
                      <th className="text-center">Sales</th>
                      <th className="text-center">Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Today</th>
                      <td className="text-center">{selectedStoreCurrencyType.currencyCode} {Number(storeDashBoardData?.salesSumary?.today?.totalSalePrice || 0.00).toFixed(2)}</td>
                      <td className="text-center">{storeDashBoardData?.salesSumary?.today?.totalSaleCount}</td>
                    </tr>
                    <tr>
                      <th scope="row">7 Days</th>
                      <td className="text-center">{selectedStoreCurrencyType.currencyCode} {Number(storeDashBoardData?.salesSumary?.sevenDay?.totalSalePrice || 0.00).toFixed(2)}</td>
                      <td className="text-center">{storeDashBoardData?.salesSumary?.sevenDay?.totalSaleCount}</td>
                    </tr>
                    <tr>
                      <th scope="row">15 Days</th>
                      <td className="text-center">{selectedStoreCurrencyType.currencyCode} {Number(storeDashBoardData?.salesSumary?.fifteenDay?.totalSalePrice || 0.00).toFixed(2)}</td>
                      <td className="text-center">{storeDashBoardData?.salesSumary?.fifteenDay?.totalSaleCount}</td>
                    </tr>
                    <tr>
                      <th scope="row">30 Days</th>
                      <td className="text-center">{selectedStoreCurrencyType.currencyCode} {Number(storeDashBoardData?.salesSumary?.thirtyDay?.totalSalePrice || 0.00).toFixed(2)}</td>
                      <td className="text-center">{storeDashBoardData?.salesSumary?.thirtyDay?.totalSaleCount}</td>
                    </tr>
                    <tr>
                      <th scope="row">90 Days</th>
                      <td className="text-center">{selectedStoreCurrencyType.currencyCode} {Number(storeDashBoardData?.salesSumary?.ninetyDay?.totalSalePrice || 0.00).toFixed(2)}</td>
                      <td className="text-center">{storeDashBoardData?.salesSumary?.ninetyDay?.totalSaleCount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div className="card-footer">
              <b>Son Güncelleme:</b> 11.05.2023 00:00
            </div> */}
          </div>
        </div>

      </div>


      <div className="row">
        <div id="draggable-bottom" className="col-xl-12 pb-5 mb-5">
          <div id="latest-orders" className="card custom-card">
            <div className="card-body flex-unset">
              <div>
                <h6 className="card-title mb-1">{t('orderWidget.title')}</h6>
              </div>
            </div>
            <div className="card-body">
              {latestOrderList && latestOrderList.length > 0 &&
                <div className="table-responsive">
                  <table className="table table-hover table-bordered border-primary">
                    <thead>
                      <tr>
                        <th>{t('orderWidget.table.orderNo')}</th>
                        <th className="text-center">{t('orderWidget.table.status')}</th>
                        <th className="text-center">{t('orderWidget.table.price')}</th>
                        <th className="text-center">{t('orderWidget.table.profit')}</th>
                        <th className="text-center">{t('orderWidget.table.orderDate')}</th>
                        <th className="text-center">{t('orderWidget.table.makeOrder')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {latestOrderList.sort((b, a) => moment(a.purchaseDate, "YYYY-MM-DDThh:mm:ss").valueOf() - moment(b.purchaseDate, "YYYY-MM-DDThh:mm:ss").valueOf()).slice(0, 10).map((orderItem, index) => {

                        return (
                          <tr key={"latestOrderItem_" + index}>
                            <td>
                              <p className="mb-0">
                                <span className="text-primary ms-2 cursor-pointer"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/order-detail/' + orderItem.id);
                                  }} >
                                  #{orderItem.orderNumber}
                                </span>
                              </p>
                            </td>
                            <td className="text-center">
                              {orderItem.orderStatusId === 0 && <span className="badge bg-info">{orderItem.orderStatus}</span>}
                              {(orderItem.orderStatusId === 1 || orderItem.orderStatus === 2) && <span className="badge bg-warning">{orderItem.orderStatus}</span>}
                              {(orderItem.orderStatusId === 3 || orderItem.orderStatusId === 4) && <span className="badge bg-success">{orderItem.orderStatus}</span>}
                              {(orderItem.orderStatusId === 5 || orderItem.orderStatusId === 6 || orderItem.orderStatusId === 7) && <span className="badge bg-danger">{orderItem.orderStatus}</span>}
                              {/* <span className="badge bg-info">{orderItem.orderStatus}</span> */}
                            </td>
                            <td className="text-center">
                              <span className="badge bg-info">
                                {selectedStoreCurrencyType.currencyCode} {orderItem.orderItemPriceAmount}
                              </span>
                            </td>
                            <td className="text-center">
                              <div>
                                <div className="row justify-content-start w-90" >
                                  <div className="col-12 px-0">
                                    <span className="text-end badge rounded-pill bg-success fs-11 ms-2">Profit: {selectedStoreCurrencyType.currencyCode} {Number(orderItem.profitPrice || 0.00).toFixed(2)}</span>
                                  </div>
                                </div>
                                <div className="row justify-content-start w-90 mt-1" >
                                  <div className="col-12 px-0">
                                    <span className="text-end badge rounded-pill bg-success fs-11 ms-2">Profit %: {Number(orderItem.profitPercent || 0.00).toFixed(2)}%</span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="text-center">
                              {moment(orderItem.purchaseDate, "YYYY-MM-DDThh:mm:ss").fromNow()}
                            </td>
                            {orderItem.b && <td className="text-center">{t('orderWidget.orderMade')}</td>}
                            <td className="text-center">
                              {/* <a className={`ms-2 cursor-pointer btn ripple btn-sm btn-teal ${!orderItem.isOrderable ? "disabled" : ""}`} target="_blank" rel="noreferrer"
                                href={"https://www.amazon.com/gp/aws/cart/add.html?AmazonOrderId=" + orderItem.id + "&sendType=1&ASIN.1=" + orderItem.products[0].asin + "&Quantity.1=" + orderItem.products[0].quantityOrdered}>
                                {t('orderWidget.table.makeOrder')}
                              </a> */}
                              <Restricted allowedTo={[
                                REACT_APP_ROLES_SUPERADMIN,
                                REACT_APP_ROLES_ADMIN,
                                REACT_APP_ROLES_ORDERS
                              ]}>
                                <button className={`ms-2 cursor-pointer btn ripple btn-sm btn-teal ${!orderItem.isOrderable ? "disabled" : ""}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(setActiveBuyoutCarrierSelectionModal({
                                      name: modals.buyoutCarrierSelection, isGlobal: true, title: "", source: orderItem.id,
                                      carrierSelectionCallback: (response) => {
                                        dispatch(clearActiveBuyoutCarrierSelectionModal());
                                      }
                                    }));

                                  }}>
                                  { }
                                  {orderItem.isOrderable ? t('orderWidget.table.makeOrder') : t('orderWidget.table.orderCompleted')}
                                </button>
                              </Restricted>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              }

            </div>
          </div>
        </div>
      </div>

      
    </>
  )
}


export default HomePage;