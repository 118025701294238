import { useEffect, useState } from "react";

function DrPagination({ paginationData, tablePageLengthChanged, tablePageNumberChanged }) {

    const [pagesList, setPagesList] = useState([1]);
    const [shownPagesList, setShownPagesList] = useState(["1"]);
    const lengthMenu = [[5, 10, 20, 40, 60, 80, 100, 200, 500, 1000, 2000, -1], [5, 10, 20, 40, 60, 80, 100, 200, 500, 1000, 2000, "All"]];

    useEffect(() => {

        if (paginationData) {
            let pageCount = 0;

            if (paginationData.totalRecordCount === 0 || (paginationData.totalRecordCount > 0 && paginationData.totalRecordCount <= (paginationData?.tablePageLength !== -1 ? paginationData?.tablePageLength : paginationData.totalRecordCount))) {
                pageCount = 1;
            }
            else {
                pageCount = parseInt((paginationData.totalRecordCount / (paginationData?.tablePageLength !== -1 ? paginationData?.tablePageLength : paginationData.totalRecordCount))) + (paginationData.totalRecordCount % (paginationData?.tablePageLength !== -1 ? paginationData?.tablePageLength : paginationData.totalRecordCount) > 0 ? 1 : 0);
            }

            let pageArray = [];
            for (let index = 0; index < pageCount; index++) {
                pageArray.push(index);
            }
            // console.log("pageArray", pageArray)
            setPagesList(pageArray);
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationData?.tablePageLength, paginationData?.totalRecordCount]);

    useEffect(() => {

        if (pagesList?.length > 0 && pagesList?.length < 6) {
            let newPageArray = Object.assign([], pagesList.slice(1, pagesList.length - 1).map(x => (x + 1).toString()))
            setShownPagesList(newPageArray);
        }
        else if (pagesList?.length >= 6) {
            let currentPageNumber = (paginationData?.tableSkip / (paginationData?.tablePageLength !== -1 ? paginationData?.tablePageLength : paginationData.totalRecordCount)) + 1;
            let newPageArray = [];


            let firstPageGroup = Object.assign([], pagesList.slice(2, currentPageNumber));
            let lastPageGroup = Object.assign([], pagesList.slice(currentPageNumber + 1, pagesList.length));
            if (firstPageGroup.length > 3) {
                newPageArray.push("...");
                if (lastPageGroup.length <= 2) {
                    newPageArray = newPageArray.concat(firstPageGroup.slice(currentPageNumber - (4 - lastPageGroup.length), currentPageNumber).map(x => x.toString()));
                }
                else {
                    newPageArray.push((currentPageNumber - 1).toString());
                }
            }
            else {
                newPageArray = newPageArray.concat(firstPageGroup.map(x => x.toString()));
            }

            if (currentPageNumber > 1 && currentPageNumber < pagesList.length) {
                newPageArray.push(currentPageNumber.toString());
            }


            if (lastPageGroup.length > 3) {
                if (firstPageGroup.length <= 2) {
                    newPageArray = newPageArray.concat(lastPageGroup.slice(0, 4 - firstPageGroup.length).map(x => x.toString()));
                }
                else {
                    newPageArray.push((currentPageNumber + 1).toString());
                }

                newPageArray.push("...");
            }
            else {
                newPageArray = newPageArray.concat(lastPageGroup.map(x => x.toString()));
            }

            setShownPagesList(newPageArray);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationData?.tableSkip, pagesList]);
    return (
        <>
            <div id="dtable_wrapper" className="dataTables_wrapper no-footer">
                <div className="row">
                    <div class="dataTables_info" id="dtable_info" role="status" aria-live="polite">
                        {paginationData?.totalRecordCount || 0} kayıttan {paginationData?.tableSkip} - {paginationData?.tableSkip + (paginationData?.tablePageLength !== -1 ? paginationData?.tablePageLength : paginationData.totalRecordCount) > paginationData?.totalRecordCount ? paginationData?.totalRecordCount : paginationData?.tableSkip + (paginationData?.tablePageLength !== -1 ? paginationData?.tablePageLength : paginationData.totalRecordCount)} arasındaki kayıtlar gösteriliyor
                    </div>
                </div>
                <div className="row justify-content-between">
                    <div className="col-auto">
                        <div class="dataTables_length" id="dtable_length">
                            <label>Sayfada
                                <select name="dtable_length" aria-controls="dtable" value={paginationData?.tablePageLength || 20}
                                    onChange={(e) => {
                                        if (tablePageLengthChanged) {
                                            tablePageLengthChanged(parseInt(e.target.value))
                                        }
                                    }}>

                                    {lengthMenu?.length > 0 && lengthMenu[0].map((lengthItem, index) => {
                                        return (
                                            <option key={"_length_" + lengthItem} value={lengthItem}>{lengthMenu[1][index]}</option>
                                        )
                                    })
                                    }
                                </select>
                                kayıt göster
                            </label>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div class="dataTables_paginate paging_simple_numbers" id="dtable_paginate">
                            <a className={`paginate_button previous  ${paginationData?.tableSkip === 0 ? "disabled" : ""}`}
                                tabindex="-1" id="dtable_previous"
                                onClick={(e) => {
                                    if (paginationData?.tableSkip !== 0 && paginationData?.tableSkip > 0) {
                                        if (tablePageNumberChanged) {
                                            tablePageNumberChanged((paginationData?.tableSkip - (paginationData?.tablePageLength !== -1 ? paginationData?.tablePageLength : paginationData.totalRecordCount)));
                                        }
                                    }
                                }}>
                                Önceki
                            </a>
                            <span>
                                <a className={`paginate_button ${paginationData?.tableSkip === 0 ? "current" : ""}`}
                                    tabindex={"0"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (paginationData?.tableSkip !== 0 && tablePageNumberChanged) {
                                            tablePageNumberChanged((0 * (paginationData?.tablePageLength !== -1 ? paginationData?.tablePageLength : paginationData.totalRecordCount)));
                                        }

                                    }}>
                                    {1}
                                </a>
                                {/* {pagesList.length > 1 && pagesList.length < 6 && pagesList.slice(1, pagesList.length - 1).map((pageNumber, index) => {
                                    return (
                                        <a className={`paginate_button ${paginationData?.tableSkip === (pageNumber * paginationData?.tablePageLength) ? "current" : ""}`}
                                            tabindex={pageNumber.toString()}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (paginationData?.tableSkip !== (pageNumber * paginationData?.tablePageLength)
                                                    && tablePageNumberChanged) {
                                                    tablePageNumberChanged((pageNumber * paginationData?.tablePageLength));
                                                }

                                            }}>
                                            {pageNumber + 1}
                                        </a>
                                    )

                                })
                                }
                                {pagesList.length > 1 && pagesList.length > 6 && pagesList.slice(1, pagesList.length - 1).map((pageNumber, index) => {
                                    return (
                                        <a className={`paginate_button ${paginationData?.tableSkip === (pageNumber * paginationData?.tablePageLength) ? "current" : ""}`}
                                            tabindex={pageNumber.toString()}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (paginationData?.tableSkip !== (pageNumber * paginationData?.tablePageLength)
                                                    && tablePageNumberChanged) {
                                                    tablePageNumberChanged((pageNumber * paginationData?.tablePageLength));
                                                }

                                            }}>
                                            {pageNumber + 1}
                                        </a>
                                    )

                                })
                                } */}
                                {shownPagesList.length > 0 && shownPagesList.map((pageNumber, index) => {
                                    return (

                                        <>
                                            {pageNumber === "..." && <span class="ellipsis">…</span>}
                                            {pageNumber !== "..." &&
                                                <a className={`paginate_button ${paginationData?.tableSkip === ((parseInt(pageNumber) - 1) * (paginationData?.tablePageLength !== -1 ? paginationData?.tablePageLength : paginationData.totalRecordCount)) ? "current" : ""}`}
                                                    tabindex={pageNumber}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (paginationData?.tableSkip !== ((parseInt(pageNumber) - 1) * (paginationData?.tablePageLength !== -1 ? paginationData?.tablePageLength : paginationData.totalRecordCount))
                                                            && tablePageNumberChanged) {
                                                            tablePageNumberChanged(((parseInt(pageNumber) - 1) * (paginationData?.tablePageLength !== -1 ? paginationData?.tablePageLength : paginationData.totalRecordCount)));
                                                        }

                                                    }}>
                                                    {pageNumber}
                                                </a>
                                            }

                                        </>

                                    )

                                })
                                }
                                {pagesList.length > 1 &&
                                    <a className={`paginate_button ${paginationData?.tableSkip === ((pagesList.length - 1) * (paginationData?.tablePageLength !== -1 ? paginationData?.tablePageLength : paginationData.totalRecordCount)) ? "current" : ""}`}
                                        tabindex={pagesList.length.toString()}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (paginationData?.tableSkip !== ((pagesList.length - 1) * (paginationData?.tablePageLength !== -1 ? paginationData?.tablePageLength : paginationData.totalRecordCount)) && tablePageNumberChanged) {
                                                tablePageNumberChanged(((pagesList.length - 1) * (paginationData?.tablePageLength !== -1 ? paginationData?.tablePageLength : paginationData.totalRecordCount)));
                                            }

                                        }}>
                                        {pagesList.length}
                                    </a>}
                            </span>
                            <a class={`paginate_button next  ${(parseInt(paginationData?.tableSkip) + parseInt((paginationData?.tablePageLength !== -1 ? paginationData?.tablePageLength : paginationData.totalRecordCount))) >= paginationData?.totalRecordCount ? "disabled" : ""}`} id="dtable_next"
                                onClick={(e) => {
                                    if ((parseInt(paginationData?.tableSkip) + parseInt((paginationData?.tablePageLength !== -1 ? paginationData?.tablePageLength : paginationData.totalRecordCount))) < paginationData?.totalRecordCount) {
                                       
                                        if ((parseInt(paginationData?.tableSkip) + parseInt((paginationData?.tablePageLength !== -1 ? paginationData?.tablePageLength : paginationData.totalRecordCount))) < paginationData?.totalRecordCount
                                            && tablePageNumberChanged) {
                                            tablePageNumberChanged((parseInt(paginationData?.tableSkip) + parseInt((paginationData?.tablePageLength !== -1 ? paginationData?.tablePageLength : paginationData.totalRecordCount))));
                                        }
                                    }
                                }}>
                                Sonraki
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
}
export default DrPagination
